import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
// import { FaBoxOpen, FaPencilAlt } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
class Products extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: '',
            selectedDuration: '',
            option: '',
            category: '',
            showPopup: false,
            showFAQsForm: false,
            // show_edit_products:false,
            title: '',
            type: '',
            message: '',
            products: [],
            id: '',
            u_productId: '',
            u_title: '',
            u_description: '',
            u_type: '',
            u_value: '',
            u_amount: '',
            u_duration: '',
            u_deal: '',
            s_totalclaimed: '',
            totalclaimed: ''
        };
        // this.state = {selectedDuration:''};
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        // this.categorychange = this.categorychange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // update form
        // this.updateproducts=this.updateproducts.bind(this);
        // this.optionChange=this.optionChange.bind(this);
    }
    componentDidMount() {
        // fetch(globaladdress + "getproducts?key=" + globalkey)
        //     .then((res) => res.json())
        //     .then((res) => {
        //         this.setState({
        //             products: res,
        //         });
        //     });
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getproducts?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {

                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        products: res,
                    });
                }
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });
    }
    togglePopup = () => {
        var role = window.localStorage.getItem('role');
        if (role !== 'Admin') {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showPopup: !this.state.showPopup });
        }
    };
    toggleedit_products = (e) => {
        var role = window.localStorage.getItem('role');
        // const u_productId = e.currentTarget.dataset.tid;
        // data - title={ pt.title }
        // data - msg={ pt.description }
        // data - selopt={ pt.type }
        // data - value={ pt.value }
        // data - amount={ pt.amount }
        // data - duration={ pt.duration }
        this.setState({ u_productId: e.currentTarget.dataset.tid, u_title: e.currentTarget.dataset.title, u_description: e.currentTarget.dataset.msg, u_type: e.currentTarget.dataset.selopt, u_value: e.currentTarget.dataset.value, u_amount: e.currentTarget.dataset.amount, u_duration: e.currentTarget.dataset.duration, u_deal: e.currentTarget.dataset.deal })
        // alert(u_productId);
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ title: e.currentTarget.dataset.title, id: e.currentTarget.dataset.nid, message: e.currentTarget.dataset.msg, selectedDuration: e.currentTarget.dataset.selopt });
            // alert(this.state.selectedDuration);
            this.setState({ show_edit_products: !this.state.show_edit_products });
        }
    };
    toogleCardStats = (e) => {
        this.setState({ s_totalclaimed: e.currentTarget.dataset.totalclaimed, u_productId: e.currentTarget.dataset.tid, u_title: e.currentTarget.dataset.title, u_description: e.currentTarget.dataset.msg, u_type: e.currentTarget.dataset.selopt, u_value: e.currentTarget.dataset.value, u_amount: e.currentTarget.dataset.amount, u_duration: e.currentTarget.dataset.duration, u_deal: e.currentTarget.dataset.deal })
        this.setState({ totalclaimed: e.currentTarget.dataset.totalclaimed, title: e.currentTarget.dataset.title, id: e.currentTarget.dataset.nid, message: e.currentTarget.dataset.msg, selectedDuration: e.currentTarget.dataset.selopt });
        // alert(this.state.selectedDuration);
        this.setState({ show_card_stats: !this.state.show_card_stats });
    }
    handleCategoryChange(event) {
        var selopt = event.currentTarget.value;
        this.setState({ selectedCategory: selopt });
    }
    handleTypeChange(event) {
        var selopt1 = event.currentTarget.value;
        this.setState({ selectedDuration: selopt1 });
    }
    //   categorychange(event) {
    //     var selcat = event.currentTarget.value;
    //     this.setState({selectedDuration: selcat});
    //   }
    //   optionChange(event) {
    //     var option1 = event.currentTarget.value;
    //     this.setState({selectedDuration: option1});
    //   }
    handleSubmit(event) {
        event.preventDefault();
        toast.info("add function called")
        // productId, title, description, type, value, amount, duration, status
        var s_title = document.getElementById('p_title').value;
        var s_description = document.getElementById('p_description').value;
        var s_value = document.getElementById('p_value').value;
        var s_amount = document.getElementById('p_amount').value;
        var s_deal = document.getElementById('p_deal').value;
        var s_type = this.state.selectedCategory;
        var s_duration = this.state.selectedDuration;
        toast.info(s_duration);
        if (s_type === 'pack') {
            if (s_duration === '') {
                toast.info(s_duration)
                toast.warning("select a valid input!");
            } else {
                toast.info(s_type, s_duration);
                try {
                    axios.post(globaladdress + "adminaddproduct?title=" + s_title + "&description=" + s_description + "&value=" + s_value + "&amount=" + s_amount + "&duration=" + s_duration + "&type=" + s_type + "&deal=" + s_deal + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                        .then((res) => {
                            if (!res.ok) {
                                throw new Error(`HTTP error! Status: ${res.status}`);
                            }
                            return res.text();
                        })
                        .then((resText) => {
                            console.log("Full response:", resText);
                            if (resText === "Session timeOut!") {
                                alert("Session timeOut!");
                                window.localStorage.clear();
                                window.location.href = "/";
                            } else {
                                window.location.reload()

                            }
                        })
                    // alert("function called in try bloack");
                } catch {
                    toast.error("api call skipped!!")
                }
            }
        } else {
            var s1_duration = '0'
            try {
                axios.post(globaladdress + "adminaddproduct?title=" + s_title + "&description=" + s_description + "&value=" + s_value + "&amount=" + s_amount + "&duration=" + s1_duration + "&type=" + s_type + "&deal=" + s_deal + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            window.location.reload()

                        }
                    })
                // alert("function called in try bloack");
            } catch {
                toast.error("api call skipped!!")
            }
        }
    }
    getRandomColor = () => {
        const getContrastYIQ = (hexcolor) => {
            const r = parseInt(hexcolor.substr(1, 2), 16);
            const g = parseInt(hexcolor.substr(3, 2), 16);
            const b = parseInt(hexcolor.substr(5, 2), 16);
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;
            return yiq;
        };
        const generateColor = () => {
            return '#' + Math.floor(Math.random() * 16777215).toString(16);
        };
        let color = generateColor();
        const customColorsToSkip = ['#FFFFFF', '#000000', '#CCCCCC', '#9eebdf', '#aafdb8', '#abf7a9', '#f7f7f7', '#fccce0', '#f7d6ac', '#fbe693', '#c9dddf', '#d3ebe6', '#e3e3b6', '#e5f7ad', '#ebfe37', '#ede9b7', '#fbf725'];
        while (getContrastYIQ(color) < 128 || customColorsToSkip.includes(color)) {
            color = generateColor();
        }
        return color;
    };
    updateproducts(event) {
        event.preventDefault();
        var dtset = {
            id: this.state.id,
            title: document.getElementById('edit_note_title').value,
            msg: document.getElementById('edit_note_message').value,
            type: this.state.selectedDuration,
            // id:this.state.id,
            // title: this.state.title,
            // msg:this.state.message,
            // type:this.state.selectedDuration,
        }
        toast.info(JSON.stringify(dtset));
        try {
            // axios.post(globaladdress+"updatereleasenotes?id="+dtset.id+"&title="+dtset.title+"&message="+dtset.msg+"&type="+dtset.type+"&key="+globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(()=>{window.location.reload()})
            toast.success("function called in try bloack");
        } catch {
            toast.error("api call skipped!!")
        }
    }
    render() {
        const randomColor = this.getRandomColor();
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    {/* <div className='page-content'> */}
                    <Header />
                    <div className='right_col'>
                        <div className='table_container'>
                            <div>
                                <button id="add_release_notes_btn" style={{ background: this.getRandomColor() }} onClick={this.togglePopup}>Add Product</button>
                                {this.state.showPopup && (
                                    <div className="popup">
                                        <div className="popup-inner">
                                            <h2>Add new Product</h2>
                                            <form onSubmit={this.handleSubmit}>
                                                <label>
                                                    Choose category:
                                                    <select defaultValue={this.state.selectedCategory} onChange={this.handleCategoryChange}>
                                                        <option value="">--select category--</option>
                                                        <option value="coins">Coins</option>
                                                        <option value="pack">Pack</option>
                                                        <option value="reward">Reward</option>
                                                    </select>
                                                </label>
                                                <label>
                                                    Choose Duration:
                                                    <select defaultValue={this.state.selectedDuration} onChange={this.handleTypeChange}>
                                                        <option value="">--select Duration--</option>
                                                        <option value="1">Monthly</option>
                                                        <option value="3">Quarterly</option>
                                                        <option value="6">Half yearly</option>
                                                        <option value="12">Yearly</option>
                                                    </select>
                                                </label>
                                                <br />
                                                <label>
                                                    Title:
                                                    <input type="text" id='p_title' />
                                                </label>
                                                <label>
                                                    Value:
                                                    <input type='number' id='p_value' />
                                                </label>
                                                <label>
                                                    Amount:
                                                    {this.state.selectedCategory !== 'reward' ? <input type='number' id='p_amount' /> : <div className='p_amount_unedit'><input type='number' id='p_amount' defaultValue="0" readOnly /></div>}
                                                </label>
                                                <label>
                                                    Deal:
                                                    <input type='number' id='p_deal' />
                                                </label>
                                                <br />
                                                <label>
                                                    Description:
                                                    <textarea id='p_description' />
                                                </label>
                                                <br />
                                                <div className='addreleasenotespopupform'>
                                                    <button type="submit">Add</button>

                                                    <button className="close-btn" onClick={this.togglePopup}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='release_notes_tab'>
                                <div className='col-md-12'>
                                    <div class="col-md-12 container">
                                        <div class="row">
                                            <div class="col-md-12 products_cards">
                                                {this.state.products && this.state.products.map((pt) => (
                                                    <div class="card-container">
                                                        <div class="flip-card">
                                                            <div class="flip-card-inner">
                                                                <div class="flip-card-front">
                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <h2 style={{ overflow: 'hidden', maxWidth: '80%', textOverflow: 'ellipsis', fontSize: '30px', fontWeight: 'bold' }}>
                                                                                {pt.title}
                                                                            </h2>
                                                                        </div>
                                                                        <h2>Type: <b>{pt.type}</b></h2>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <p className='card_actual_value' style={{ paddingRight: "10px" }}> Actual Value: <b>{pt.value}</b></p>
                                                                            <p className='card_final_amount'>Final Amount: <b>{pt.amount}</b></p>
                                                                        </div>
                                                                        <div class="discount-tag-wrapper">
                                                                            {pt.deal !== '' && (
                                                                                <div class="badge-area-2">
                                                                                    <div class="badge-product-sale">
                                                                                        <span class="new">Discount</span>
                                                                                        <span class="percent">{pt.deal} %</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                                                            <h2>Total claimed: {pt.status_not_zero_count}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="flip-card-back" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <h2 style={{ overflow: 'hidden', maxWidth: '80%', textOverflow: 'ellipsis', fontSize: '30px', fontWeight: 'bold' }}>
                                                                            {pt.title}
                                                                        </h2>
                                                                    </div>                                                                    <p class="card_description" id="descriptionText" style={{ maxHeight: "55%", overflowY: "scroll" }}>
                                                                        {pt.description}
                                                                    </p>
                                                                    <div style={{ marginTop: 'auto' }}>
                                                                        <button
                                                                            style={{ background: "#000", border: "none", borderRadius: "5px", padding: "5px" }}
                                                                            data-tid={pt.productId}
                                                                            data-title={pt.title}
                                                                            data-msg={pt.description}
                                                                            data-selopt={pt.type}
                                                                            data-value={pt.value}
                                                                            data-amount={pt.amount}
                                                                            data-duration={pt.duration}
                                                                            data-deal={pt.deal}
                                                                            type="submit"
                                                                            onClick={this.toggleedit_products}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <FaBoxOpen size={20} style={{ float: "left", margin: " 30px 30px 0px 30px", color: "#fff" }} /> */}
                                                        {/* <FaPencilAlt size={20} style={{ float: "right", margin: " 30px 30px 0px 30px", color: "#fff" }} data-tid={pt.productId} data-title={pt.title} data-msg={pt.description} data-selopt={pt.type} data-value={pt.value} data-amount={pt.amount} data-duration={pt.duration} data-deal={pt.deal} type="submit" onClick={this.toggleedit_products} /> */}
                                                        {/* <a data-tid={pt.productId} data-title={pt.title} data-msg={pt.description} data-selopt={pt.type} data-value={pt.value} data-amount={pt.amount} data-duration={pt.duration} data-deal={pt.deal} type="submit" onClick={this.toogleCardStats}> */}
                                                        {/* <div class="card order-card" style={{ background: this.getRandomColor() }}> */}
                                                        {/* <div class="card-block"> */}
                                                        {/* <h6 class="card_id" style={{ display: "none" }}>{pt.productId}</h6> */}
                                                        {/* <h2 className="card_title" style={{ fontWeight: "bold" }}>Title: {pt.title}</h2><h4 class="card_type" style={{ fontWeight: "bold" }}>Type: {pt.type}</h4> */}
                                                        {/* <br /> */}
                                                        {/* <h2>Total claimed: {pt.status_not_zero_count}</h2> */}
                                                        {/* <div class="card_description_container"> */}
                                                        {/* <p class="card_description" id="descriptionText">{pt.description}</p> */}
                                                        {/* <a data-tid={pt.productId} data-title={pt.title} data-msg={pt.description} data-selopt={pt.type} data-value={pt.value} data-amount={pt.amount} data-duration={pt.duration} data-deal={pt.deal} data-totalclaimed={pt.status_not_zero_count} type="submit" href="#" id="showMoreLink" onClick={this.toogleCardStats}>Show More</a> */}
                                                        {/* <br></br> */}
                                                        {/* </div> */}
                                                        {/* <p className='card_actual_value' style={{ fontWeight: "bold" }}> Actual Value: {pt.value}<span className='card_discount' style={{ float: "right" }}>Discount: {pt.duration}%</span></p> */}
                                                        {/* <p className='card_final_amount' style={{ fontWeight: "bold" }}>Final Amount: {pt.amount}</p> */}
                                                        {/* <button style={{ float: "right", background: "#000", border: "none", borderRadius: "5px", padding: "5px" }} data-tid={pt.productId} data-title={pt.title} data-msg={pt.description} data-selopt={pt.type} data-value={pt.value} data-amount={pt.amount} data-duration={pt.duration} data-deal={pt.deal} data-totalclaimed={pt.status_not_zero_count} type="submit" href="#" id="showMoreLink" onClick={this.toogleCardStats}>Show More</button> */}
                                                        {/*  */}
                                                        {/* </div> */}
                                                        {/* </div> */}
                                                        {/* </a> */}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.show_edit_products && (
                                    <div className="edit_app_notes">
                                        <div className="edit_app_notes-inner">
                                            <h2>Update Product's</h2>
                                            {/* <button id="delete_product_lkp_btn" style={{ float: 'right' }} onClick={this.deleteProductLkp}>Delete</button> */}
                                            <br></br>
                                            <form onSubmit={this.updateproducts}>
                                                <label>
                                                    Choose category:
                                                    <select defaultValue={this.state.selectedCategory} onChange={this.handleCategoryChange}>
                                                        <option value="">--select category--</option>
                                                        <option value="coins">Coins</option>
                                                        <option value="pack">Pack</option>
                                                        <option value="reward">Reward</option>
                                                    </select>
                                                </label>
                                                <label>
                                                    Choose Duration:
                                                    <select defaultValue={this.state.selectedDuration} onChange={this.handleTypeChange}>
                                                        <option value="">--select Duration--</option>
                                                        <option value="1">Monthly</option>
                                                        <option value="3">Quarterly</option>
                                                        <option value="6">Half yearly</option>
                                                        <option value="12">Yearly</option>
                                                    </select>
                                                    {/* <p>ProductId: {u_productId}</p> */}
                                                </label>
                                                <br />
                                                <label>
                                                    Title:
                                                    <input id='edit_note_title' type="text" defaultValue={this.state.title} />
                                                </label>
                                                <br />
                                                <label>
                                                    Message:
                                                    <textarea id='edit_note_message' defaultValue={this.state.message} />
                                                </label>
                                                <br />
                                                <div className='editreleasenotespopupform'>
                                                    <button type="submit">Update</button>
                                                    <button className="close-btn" data-nid='' data-title='' data-msg='' data-selopt='Bug Fix' style={{ float: 'right', padding: '10px' }} onClick={this.toggleedit_products}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                                {this.state.show_card_stats && (
                                    <div className="products_stats_form">
                                        <div className="products_stats_form-inner">
                                            <h2>Product Stats</h2>
                                            <br />
                                            <p>Product Title: {this.state.u_title}</p>
                                            <br />
                                            <p>Description: {this.state.u_description}</p>
                                            <br />
                                            <p>Type: {this.state.u_type}</p>
                                            <br />
                                            <p>Final amount: {this.state.u_amount}</p>
                                            <br />
                                            <p>Actual price: {this.state.u_value}</p>
                                            <br />
                                            <p>Discount: {this.state.u_duration}%</p>
                                            <br />
                                            <p>Total claim's: {this.state.s_totalclaimed}</p>
                                            <br></br>
                                            <form>
                                                <div className='editreleasenotespopupform'>
                                                    <button className="close-btn" data-nid='' data-title='' data-msg='' data-selopt='Bug Fix' style={{ float: 'right', padding: '10px', borderRadius: "5px" }} onClick={this.toogleCardStats}>
                                                        Close
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default Products;