import React, { Component } from "react";
import ReactLoading from "react-loading";
import Navbar from "../../components/Navbar";
import Header from '../../components/Header/header';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import LogoWhite from '../assets/images/logo5.svg'
import { globalkey, globaladdress, s_key, s_secret } from '../variables';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      searchText: '',
      DataisLoaded: false,
      usersList: [],
      maintenancestat: null,
      selectedUsrIndex: null,
      role: window.localStorage.getItem('role'),
      selectedUser: {
        status: null,
        firstName: null,
        email: null
      },
      showModal: false,
    };
    this.inputcheck = this.inputcheck.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }
  handleInputChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  handleSearch = (event) => {
    this.setState({ searchText: event.target.value });
  };
  signincheck() {
    if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  }
  deleteUser = () => {
    alert("delete user function called");
    var Del_UserID = this.state.selectedUsrIndex
    var coolDownPeriod = this.state.selectedUser.days_since_activity
    // admindeleteuseraccount
    // if (Del_UserID && coolDownPeriod >= 2) {
    if (Del_UserID) {
      try {
        // axios.post(globaladdress + "adminaddproduct?userId=" + Del_UserID + "&key=" + globalkey).then(() => { window.location.reload() })
        axios.delete(globaladdress + "admindeleteuseraccount?userId=" + Del_UserID + "&key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
          .then((response) => {
            const d_user_s_res = response.data
            if (d_user_s_res === "Session timeOut!") {
              window.localStorage.clear();
              window.location.href = "/"
            }
            else if (d_user_s_res === "User Account Deleted successfully!") {
              window.location.reload();
            } else {
              console.error("Server responded with an error:", response.status, response.statusText);
            }
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      } catch {
        alert("api call skipped!!")
      }
    } else {
      if (!Del_UserID) {
        alert("An error occurred while attempting to delete the user account!")
      }
    }

    // } else {
    //   if (!Del_UserID) {
    //     alert("An error occurred while attempting to delete the user account!")
    //   } else if (coolDownPeriod < 2) {
    //     alert("user is in cool down period " + coolDownPeriod + " days")
    //   }
    // }
  }
  handleClose() { this.setState({ showModal: false }) }
  handleShow() { this.setState({ showModal: true }) }
  componentDidMount() {
    let sessionTimeOutAlertShown = false;
    this.signincheck();
    fetch(globaladdress + "getdeletedusers?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            usersList: res,
          });
        }

      }).catch((error) => {
        console.log("Fetch error: ", error);
      })
    fetch(globaladdress + "getmaintenancestatus?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            maintenancestat: res,
          });
        }

      }).catch((error) => {
        console.log("Fetch error: ", error);
      })
    console.log(this.state.maintenancestat);
  }
  inputcheck(e) {
    var el = e.currentTarget;
    var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
    if (el.value !== '' && !el.classList.contains('hastext')) {
      el.classList.add('hastext')
      if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    } else if (el.value === '' && el.classList.contains('hastext')) {
      el.classList.remove('hastext')
      alertmsg.add('invalid')
    }
  }
  selectUser(e) {
    var unselect = document.getElementsByClassName('selected');
    if (unselect.length !== 0) unselect[0].classList.remove('selected');
    e.currentTarget.classList.add('selected');
    var cdId = e.currentTarget.dataset.cdid;
    var index = this.state.usersList.findIndex(x => x.cdId == cdId);
    // var tempstatus = this.state.usersList[index].status
    // cdId, firstName, lastName, displayName, mobile, email, dob, gender, 
    // maritalstatus, address, district, state, country, status, cId, image
    const seltusr = {
      cdid: this.state.usersList[index].cdId,
      firstname: this.state.usersList[index].firstName,
      lastname: this.state.usersList[index].lastName,
      displayname: this.state.usersList[index].displayName,
      mobile: this.state.usersList[index].mobile,
      email: this.state.usersList[index].email,
      dob: this.state.usersList[index].dob,
      gender: this.state.usersList[index].gender,
      maritalstatus: this.state.usersList[index].maritalstatus,
      address: this.state.usersList[index].address,
      district: this.state.usersList[index].district,
      state: this.state.usersList[index].state,
      country: this.state.usersList[index].country,
      cid: this.state.usersList[index].cId,
      image: this.state.usersList[index].image,
      days_since_activity: this.state.usersList[index].days_since_activity
    }
    this.setState({ selectedUser: seltusr, selectedUsrIndex: cdId, showModal: true });
  }
  render() {
    const { usersList, searchText } = this.state;
    const filteredList = usersList.filter(item =>
      item.displayName.toLowerCase().includes(searchText.toLowerCase()) || item.mobile.toLowerCase().includes(searchText.toLowerCase())
    );
    return <>
      <div className="container body">
        <div className='main_container'>
          <div className='col-md-3 left_col'>
            <Navbar username={localStorage.username} />
          </div>
          {/* <div className='page-content'> */}
          <Header />
          <div className='right_col'>
            <div className='container2'>
              <div className='content'>
                <h1>Deleted User's</h1>
                {/* <marquee behavior="scroll" direction="left">Maintenance mode status will be scrolling here...</marquee>
              {this.state.maintenancestat ==="true" &&
               <marquee behavior="scroll" direction="left">Server is under maintenance mobile client will be down...</marquee>
              } */}
                <hr />
                <div className="main-content">
                  <div className="">
                    <input
                      id="user_search_box"
                      type="text"
                      onChange={this.handleSearch}
                      placeholder="Search deleted user..."
                      value={searchText}
                    />
                    <div className="user-list">
                      {!this.state.DataisLoaded && <div className="loader-back">
                        <div className="loader-element">
                          <img
                            className="loader-svg"
                            src={LogoWhite}
                            alt='Jagbandhu'
                          />
                          <ReactLoading
                            type="spinningBubbles"
                            color="#FFFFFF"
                            height={300}
                            width={150}
                          />
                        </div>
                      </div>}
                      {this.state.DataisLoaded && this.state.usersList && filteredList.map((usersl, index) => {
                        return <a key={usersl.cdId} data-idx={index} data-cdid={usersl.cdId} onClick={this.selectUser} className="user_card">
                          <div className="user_image_container">
                            <img
                              className="user_image"
                              src={"data:image/png;base64," + usersl.image}
                              alt="user profile image"
                            />
                          </div>
                          <div className="text-center">
                            <h5 className="user_name">{usersl.firstName + ' ' + usersl.lastName}</h5>
                            <h6 className="display_name">{usersl.displayName}</h6>
                            <h6 className="mobile">{usersl.mobile}</h6>

                          </div>
                        </a>;
                      }
                      )}
                      {!this.state.usersList && <div><p>No Users to show</p></div>}
                    </div>
                  </div>
                  {this.state.role === "Admin" &&
                    <Modal
                      show={this.state.showModal}
                      onHide={this.handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header>
                        <Modal.Title>Delete User</Modal.Title>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={this.handleClose}>
                          <i className="fa fa-times"></i>
                        </button>
                      </Modal.Header>
                      <Modal.Body>
                        {this.state.selectedUsrIndex &&
                          <LayerDetails key={this.state.selectedUsrIndex} user={this.state.selectedUser} />}
                        {!this.state.selectedUsrIndex && <p>No User Selected</p>}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          id="btn_delete_user"
                          variant="outline-danger"
                          onClick={this.deleteUser}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}
class LayerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsrIndex: null,
      firstname: null,
      lastname: null,
      // frnid: '',
      // famid: '',
      // efullname: '',
      // edisplayname: '',
      // edob: '',
      // emobile1: '',
      // efdisplayname: '',
      selectedUser: {
        role: window.localStorage.getItem('role'),
        firstName: null,
        lastName: null,
        cdid: null,
        displayname: null,
        email: null,
        userid: null,
        firstname: null,
        lastname: null,
        status: null,
        mobile: null,
        dob: null,
        show_user_frnd_update_form: false,
        show_user_family_update_form: false,
        selectedOption: '',
        // edisplayname: '',
        edob: '',
        days_since_activity: null,
        // emobile1:'',
      },
      role: window.localStorage.getItem('role'),
      userDetCount: [],
      // usr_family_mem_list: [],
      // usr_frnd_list: [],
      // usr_event_list: [],
      // usr_inv_event_list: [],
      // usr_circle_list: [],
      // usr_invitations_list: [],
      // usr_templates_list: [],
      // user_update_data: [],
    }
    // update user frnd from function
    // this.user_frnd_gen_change = this.user_frnd_gen_change.bind(this);
    // this.updateusrfrnddetails = this.updateusrfrnddetails.bind(this);
    // this.updateusrfamilydetails = this.updateusrfamilydetails.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedUser: this.props.user });
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getuserdetcount?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            userDetCount: res,
          });
        }

      }).catch((error) => {
        console.log("Error in Fetching User Details", error);
      })
    // fetch(globaladdress + "getuserfriends?key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.setState({
    //       usr_frnd_list: res,
    //     });
    //   });
    // fetch(globaladdress + "getuserevents?key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.setState({
    //       usr_event_list: res.customer_created_events,
    //       usr_inv_event_list: res.customer_invited_events
    //     });
    //   });
    // fetch(globaladdress + "getusercircles?key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.setState({
    //       usr_circle_list: res,
    //     });
    //   });
    // fetch(globaladdress + "getuserinvitations?key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.setState({
    //       usr_invitations_list: res,
    //     });
    //   });
    // fetch(globaladdress + "getusertemplates?key=" + globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.setState({
    //       usr_templates_list: res,
    //     });
    //   });
  }
  // showForm() {
  //   document.querySelector(".updateuserform").style.display = "flex";
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#closeform3').style.display = "none";
  // }
  // closeForm() {
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // showfamilytree() {
  //   document.querySelector(".showtree").style.display = "flex";
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  //   document.querySelector('#closeform3').style.display = "none";
  //   document.querySelector('#showfriendsbtn').style.display = "flex";
  // }
  // closeForm1() {
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  // }
  // showfriends() {
  //   document.querySelector('.showfriends').style.display = "flex";
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // closeFriends() {
  //   document.querySelector('#closeform3').style.display = "none";
  //   document.querySelector('#showfriendsbtn').style.display = "flex";
  // }
  // showeventslist() {
  //   document.querySelector('.showevents').style.display = "flex";
  //   document.querySelector('.showfriends').style.display = "none";
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // closeevents() {
  //   document.querySelector('#closeform7').style.display = "none";
  //   document.querySelector('#showeventsbtn').style.display = "flex";
  //   // showeventsbtn
  // }
  // showinvitationslist() {
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "flex";
  //   document.querySelector('.showfriends').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // closeinvitations() {
  //   document.querySelector('#closeform5').style.display = "none";
  //   document.querySelector('#showinvitations').style.display = "flex";
  // }
  // showtemplateslist() {
  //   document.querySelector('.showtemplates').style.display = "flex";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector('.showcircles').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showfriends').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // closetemplates() {
  //   document.querySelector('#closeform6').style.display = "none";
  //   document.querySelector('#showtemplates').style.display = "flex";
  // }
  // showusercircleslist() {
  //   document.querySelector('.showcircles').style.display = "flex";
  //   document.querySelector('.showfriends').style.display = "none";
  //   document.querySelector('.showinvitations').style.display = "none";
  //   document.querySelector('.showtemplates').style.display = "none";
  //   document.querySelector('.showevents').style.display = "none";
  //   document.querySelector("#closeform2").style.display = "none";
  //   document.querySelector('#showfamilytree').style.display = "flex";
  //   document.querySelector("#closeform1").style.display = "none";
  //   document.querySelector('#showusereditfrom').style.display = "block";
  // }
  // closecircles() {
  //   document.querySelector('#closeform4').style.display = "none";
  //   document.querySelector('#showusercircles').style.display = "flex";
  // }
  inputcheck(e) {
    var el = e.currentTarget;
    var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
    if (el.value !== '' && !el.classList.contains('hastext')) {
      el.classList.add('hastext')
      if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    } else if (el.value === '' && el.classList.contains('hastext')) {
      el.classList.remove('hastext')
      alertmsg.add('invalid')
    }
  }
  // updateUserDetails(e) {
  //   var uuserId = e.currentTarget.dataset.ucdid;
  //   var existmobile = e.currentTarget.dataset.emobile;
  //   var first_Name, lastName, displayName, mobile, email, dob, gender, maritalstatus, address, district, state, country, status;
  //   first_Name = document.getElementById("first_Name").value;
  //   lastName = document.getElementById("lastName").value;
  //   displayName = document.getElementById("displayName").value;
  //   mobile = document.getElementById("mobile").value;
  //   email = document.getElementById("email").value;
  //   dob = document.getElementById("dob").value;
  //   gender = document.getElementById("gender").value;
  //   maritalstatus = document.getElementById("maritalstatus").value;
  //   address = document.getElementById("address").value;
  //   district = document.getElementById("district").value;
  //   state = document.getElementById("state").value;
  //   country = document.getElementById("country").value;
  //   status = document.getElementById("status").value;
  //   if (first_Name && lastName && displayName && mobile && email && dob && gender && maritalstatus) {
  //     var formdata = new FormData();
  //     formdata.append('userId', uuserId);
  //     formdata.append("first_name", first_Name)
  //     formdata.append("last_name", lastName);
  //     formdata.append("display_name", displayName);
  //     formdata.append("mobile", mobile);
  //     formdata.append('exesting_mobile', existmobile);
  //     formdata.append("email", email);
  //     formdata.append("dob", dob);
  //     formdata.append("gender", gender);
  //     formdata.append("marital_status", maritalstatus);
  //     formdata.append("address", address);
  //     formdata.append("district", district);
  //     formdata.append("state", state);
  //     formdata.append("country", country);
  //     formdata.append("status", status);
  //     axios.post(globaladdress + 'updateuserdetailsadmin?key=' + globalkey, formdata)
  //       .then(response => {
  //         window.location.reload();
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       })
  //   } else {
  //     alert("required fields cannot be empty")
  //   }
  // }
  // toogle_user_frnd_update_form = (e) => {
  //   this.setState({ frnid: e.currentTarget.dataset.frndid, edob: e.currentTarget.dataset.edob, selectedOption: e.currentTarget.dataset.selopt, edisplayname: e.currentTarget.dataset.edisplayname, emobile1: e.currentTarget.dataset.emobile1 });
  //   this.setState({ show_user_frnd_update_form: !this.state.show_user_frnd_update_form });
  // };
  // toogle_user_family_update_form = (e) => {
  //   this.setState({ famid: e.currentTarget.dataset.famid });
  //   this.setState({ show_user_family_update_form: !this.state.show_user_family_update_form });
  // };
  // updateusrfrnddetails(event) {
  //   event.preventDefault();
  //   // type:this.state.selectedOption,
  //   var updatedtset = {
  //     id: this.state.frnid,
  //     displayName: document.getElementById('edit_display_name').value,
  //     dob: document.getElementById('edit_dob').value,
  //     mobile: document.getElementById('edit_number').value,
  //     gender: this.state.selectedOption
  //     // dob: this.state.selectedUser.edob,
  //     // mobile: this.state.emobile1
  //   }
  //   alert("userId: " + updatedtset.id);
  //   alert("Display Name: " + updatedtset.displayName);
  //   alert("DOB: " + updatedtset.dob);
  //   alert("Mobile: " + updatedtset.mobile);
  //   alert("Gender: " + updatedtset.gender);
  //   try {
  //     alert("function called in try bloack updateusrfrnddetails");
  //   } catch {
  //     alert("api call skipped!!")
  //   }
  // }
  // updateusrfamilydetails(event) {
  //   event.preventDefault();
  //   var famupdtst = {
  //     id: this.state.famid,
  //     displayName: document.getElementById('edit_fam_display_name').value
  //   }
  //   alert("Family member Id: " + famupdtst.id);
  //   alert('Displau Name: ' + famupdtst.displayName);
  //   // try {
  //   //   alert("function called in try bloack updateusrfrnddetails");
  //   // } catch {
  //   //   alert("api call skipped!!")
  //   // }
  // }
  // user_frnd_gen_change(event) {
  //   var option1 = event.currentTarget.value;
  //   this.setState({ selectedOption: option1 });
  // }
  render() {
    return <div className="user_det_view">
      <div className="non-editable-details">
        <div className="user_det_edit_form">
          <div className="user_preview_sectn">
            <div className="templ_view_container">
              <img className="user_image_view" src={"data:image/png;base64," + this.state.selectedUser.image} alt="User Details" />
              {/* {this.state.role === "Admin" &&
                <div>
                  <button id="showusereditfrom" type="submit" onClick={this.showForm}>Update User</button>
                </div>
              } */}
            </div>
            <div className="templ_view_details">
              <h4 className="templ_view_title">Full Name: {this.state.selectedUser.firstname + ' ' + this.state.selectedUser.lastname}</h4>
              <h5> Display Name: {this.state.selectedUser.displayname}</h5>
              <h5> Mobile: {this.state.selectedUser.mobile}</h5>
              <h5> Gender: {this.state.selectedUser.gender}</h5>
              <h5> DOB: {this.state.selectedUser.dob}</h5>
              <h5> Marital Status: {this.state.selectedUser.maritalstatus}</h5>
              <h5> Cool Down time Period: {this.state.selectedUser.days_since_activity}</h5>
              <h5>User Friends: {this.state.userDetCount.frndsCount}</h5>
              <h5>User Family: {this.state.userDetCount.familyCount}</h5>
              <h5>User Circles: {this.state.userDetCount.userCircleCount}</h5>
              <h5>User Events: {this.state.userDetCount.eventCount}</h5>
              <h5>User Templates: {this.state.userDetCount.userPurchasedTemplates}</h5>
              <h5>User Invitations: {this.state.userDetCount.userInvitations}</h5>
              <h5>User Leaves: {this.state.userDetCount.userLeaves}</h5>
            </div>
          </div>
          <div>
            {/* <div className="addrelbtn">
              <ul className="nav nav-pills" id="user_btns">
                <li><button id="showfamilytree" type="submit" onClick={this.showfamilytree}>Family Members</button></li>
                <li><button id="showfriendsbtn" type="submit" onClick={this.showfriends}>Friends</button></li>
                <li><button id="showeventsbtn" type="submit" onClick={this.showeventslist}>Events</button></li>
                <li><button id="showinvitations" type="submit" onClick={this.showinvitationslist}>Invitations</button></li>
                <li><button id="showtemplates" type="submit" onClick={this.showtemplateslist}>Templates</button></li>
                <li><button id="showusercircles" type="submit" onClick={this.showusercircleslist}>circles</button></li>
              </ul>
            </div> */}
            {/* <div className="addrelmainformcntr"> */}
            {/* <div className="updateuserform" id="closeform1">
                <h1>Update user details</h1>
                <form className="update_user_form" id="updateuser_form">
                  <div className="updateuser_form">
                    <label id="updateuser_form_lable">First Name: </label>
                    <input className="updateuser_form_input" id="first_Name" placeholder={this.state.selectedUser.firstname} type="text" defaultValue={this.state.selectedUser.firstname} align='right' onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Last Name: </label>
                    <input className="updateuser_form_input" id="lastName" placeholder={this.state.selectedUser.lastname} type="text" defaultValue={this.state.selectedUser.lastname} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Display Name: </label>
                    <input className="updateuser_form_input" id="displayName" placeholder={this.state.selectedUser.displayname} type="text" defaultValue={this.state.selectedUser.displayname} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Mobile: </label>
                    <input className="updateuser_form_input" id="mobile" placeholder={this.state.selectedUser.mobile} type="text" defaultValue={this.state.selectedUser.mobile} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Email: </label>
                    <input className="updateuser_form_input" id="email" placeholder={this.state.selectedUser.email} type="text" defaultValue={this.state.selectedUser.email} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Date of Birth: </label>
                    <input className="updateuser_form_input" id="dob" placeholder={this.state.selectedUser.dob} type="text" defaultValue={this.state.selectedUser.dob} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Gender: </label>
                    <input className="updateuser_form_input" id="gender" placeholder={this.state.selectedUser.gender} type="text" defaultValue={this.state.selectedUser.gender} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Marital status: </label>
                    <input className="updateuser_form_input" id="maritalstatus" placeholder={this.state.selectedUser.maritalstatus} type="text" defaultValue={this.state.selectedUser.maritalstatus} onChange={this.inputcheck} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Address: </label>
                    <input className="updateuser_form_input" id="address" placeholder={this.state.selectedUser.address} type="text" defaultValue={this.state.selectedUser.address} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">District: </label>
                    <input className="updateuser_form_input" id="district" placeholder={this.state.selectedUser.district} type="text" defaultValue={this.state.selectedUser.district} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">State: </label>
                    <input className="updateuser_form_input" id="state" placeholder={this.state.selectedUser.state} type="text" defaultValue={this.state.selectedUser.state} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">Country: </label>
                    <input className="updateuser_form_input" id="country" placeholder={this.state.selectedUser.country} type="text" defaultValue={this.state.selectedUser.country} />
                    <span className="validation-alert"> * cannot be empty</span>
                    <br /><br />
                    <label id="updateuser_form_lable">status: </label>
                    <input className="updateuser_form_input" id="status" placeholder={this.state.selectedUser.status} type="text" defaultValue={this.state.selectedUser.status} />
                    <br /><br />
                  </div>
                  <br />
                </form>
                <div id="form_btn_section">
                  <span><button id='updateusr' type='submit' data-emobile={this.state.selectedUser.mobile} data-ucdid={this.state.selectedUser.cdid} onClick={this.updateUserDetails}>Update details</button>
                    <button id='close_edit_form' type="submit" onClick={this.closeForm}>Close</button></span>
                </div>
              </div> */}
            {/* ---------------------------Family Members List------------------------------- */}
            {/* <div className="showtree" id="closeform2"> */}
            {/* <div className='table_container'>
                  <div className='release_notes_tab'>
                    <input id="user_search_box_1" type="text" placeholder="Search user family members..." />
                    <table id="user_familymembers_table" key={this.state.usr_family_mem_list.rId}>
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <th>Relation</th>
                          {this.state.role === "Admin" &&
                            <th>Action</th>
                          }
                        </tr>
                        {this.state.usr_family_mem_list.length === 0 &&
                          <tr>
                            <td>No friends yet</td>
                          </tr>
                        }
                        {this.state.usr_family_mem_list && this.state.usr_family_mem_list.map((fmal) => (
                          <tr key={fmal.id}>
                            <td>{fmal.displayName}</td>
                            <td>{fmal.relationName}</td>
                            {this.state.role === "Admin" &&
                              <td>
                                <button id='edit_release_notes' data-famid={fmal.id} data-famdisplayname={fmal.displayName} data-fammobile={fmal.mobile} data-famgender={fmal.gender} type="submit" onClick={this.toogle_user_family_update_form}>Update</button>
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {this.state.show_user_family_update_form && (
                      <div className="edit_app_notes">
                        <div className="edit_app_notes-inner">
                          <h2>Update Release Note</h2>
                          <form onSubmit={this.updateusrfamilydetails}>
                            <label>
                              Display Name:
                              <textarea id='edit_fam_display_name' defaultValue={this.state.efdisplayname} />
                            </label>
                            <br />
                            <div className='editreleasenotespopupform'>
                              <button type="submit">Send</button>
                              <button className="close-btn" data-famid='' onClick={this.toogle_user_family_update_form}>
                                Close form
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
            {/* <div id="family_tree_sec">
                  <h1>Family Members List</h1>
                  <table id="user_familymembers_table" key={this.state.usr_family_mem_list.rId}>
                    <tr>
                      <th>Name</th>
                      <th>Relation</th>
                    </tr>
                    {this.state.usr_family_mem_list.length === 0 &&
                      <tr>
                        <td>No family members yet</td>
                        <td>No family members here</td>
                      </tr>
                    }
                    {this.state.usr_family_mem_list && this.state.usr_family_mem_list.map((fmal) => (
                      <tr>
                        <td>{fmal.displayName}</td>
                        <td>{fmal.relationName}</td>
                      </tr>
                    ))}
                  </table>
                  <br />
                  <button id='close_edit_form' type="submit" onClick={this.closeForm1}>Close</button>
                </div> */}
            {/* </div> */}
            {/* ---------------------------Friend's List------------------------------- */}
            {/* <div className="showfriends" id="closeform3">
                <div className='table_container'>
                  <div className='release_notes_tab'>
                    <table id="user_friends_table" key={this.state.usr_frnd_list.id}>
                      <tbody>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          {this.state.role === "Admin" &&
                            <th>Action</th>
                          }
                        </tr>
                        {this.state.usr_frnd_list.length === 0 &&
                          <tr>
                            <td>No friends yet</td>
                          </tr>
                        }
                        {this.state.usr_frnd_list && this.state.usr_frnd_list.map((ffal) => {
                          var dob = ffal.dob.split("-")[2] + "-" + ffal.dob.split("-")[1] + "-" + ffal.dob.split("-")[0];
                          return <tr key={ffal.id}>
                            <td>{ffal.id}</td>
                            <td>{ffal.displayName}</td>
                            {this.state.role === "Admin" &&
                              <td>
                                <button id='edit_release_notes' data-frndid={ffal.id} data-edisplayname={ffal.displayName} data-emobile1={ffal.mobile} data-selopt={ffal.gender} data-edob={dob} type="submit" onClick={this.toogle_user_frnd_update_form}>Update</button>
                              </td>
                            }
                          </tr>;
                        })}
                      </tbody>
                    </table>
                    {this.state.show_user_frnd_update_form &&
                      (
                        <div className="edit_app_notes">
                          <div className="edit_app_notes-inner">
                            <h2>Update Release Note</h2>
                            <form onSubmit={this.updateusrfrnddetails}>
                              <br />
                              <label>
                                Display Name:
                                <textarea id='edit_display_name' defaultValue={this.state.edisplayname} />
                              </label>
                              <br />
                              <label>
                                Mobile Number:
                                <input id='edit_number' type="number" maxLength={10} defaultValue={this.state.emobile1} />
                              </label>
                              <br />
                              <label>
                                Choose Gender:
                                <select defaultValue={this.state.selectedOption} onChange={this.user_frnd_gen_change}>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </label>
                              <label>
                                Date of Birth:
                                <input type="date" id='edit_dob' defaultValue={this.state.edob} />
                              </label>
                              <br />
                              <div className='editreleasenotespopupform'>
                                <button type="submit">Send</button>
                                <button className="close-btn" data-frndid='' data-frndisplayname='' data-frnmobile='' data-selopt='' onClick={this.toogle_user_frnd_update_form}>
                                  Close form
                                </button>
                              </div>
                            </form>

                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div> */}
            {/* ---------------------------Customer Events List------------------------------- */}
            {/* <div className="showevents" id="closeform7">
                <div id="events_sec">
                  <h1>customer createdEvents List</h1>
                  <table id="user_events_table">
                    <tbody>
                      <tr>
                        <th>Event Name</th>
                      </tr>
                      {this.state.usr_event_list.length === 0 &&
                        <tr>
                          <td>No Events yet</td>
                        </tr>
                      }
                      {this.state.usr_event_list.map((uel) => (

                        <tr>
                          <td>{uel.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <table id="user_events_table">
                    <tbody>
                      <tr>
                        <th> Customer Invited Event Name</th>
                      </tr>
                      {this.state.usr_inv_event_list.length === 0 &&
                        <tr>
                          <td>No Invitation's</td>
                        </tr>
                      }
                      {this.state.usr_inv_event_list.map((uiel) => (
                        <tr>
                          <td>{uiel.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button id='close_events_form' type="submit" onClick={this.closeevents}>Close</button>
                </div>
              </div> */}
            {/* ---------------------------User circles List------------------------------- */}
            {/* <div className="showcircles" id="closeform4">
                <div id="circles_sec">
                  <h1>Circles List</h1>
                  <table id="user_circles_table">
                    <tbody>
                      <tr>
                        <th>Circle Name</th>
                        <th>Count</th>
                      </tr>
                      {this.state.usr_circle_list.length === 0 &&
                        <tr>
                          <td>No Data</td>
                          <td>No Data</td>
                        </tr>
                      }
                      {this.state.usr_circle_list.map((ucl) => (
                        <tr>
                          <td>{ucl.title}</td>
                          <td>{ucl.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button id='close_circles_form' type="submit" onClick={this.closecircles}>Close</button>
                </div>
              </div> */}
            {/* ---------------------------User Invitation's List------------------------------- */}
            {/* <div className="showinvitations" id="closeform5">
                <div id="invitations_sec">
                  <h1>Invitations</h1>
                  <table id="user_invitations_table">
                    <tbody>
                      <tr>
                        <th>Invitation Id</th>
                        <th>Invitation Name</th>
                      </tr>
                      {this.state.usr_invitations_list.length === 0 &&
                        <tr>
                          <td>No Data</td>
                          <td>No Data</td>
                        </tr>
                      }
                      {this.state.usr_invitations_list.map((uil) => (
                        <tr>
                          <td>{uil.invId}</td>
                          <td>{uil.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button id='close_invitations_form' type="submit" onClick={this.closeinvitations}>Close</button>
                </div>
              </div> */}
            {/* ---------------------------User Template's List------------------------------- */}
            {/* <div className="showtemplates" id="closeform6">
                <div id="templates_sec">
                  <h1>Templates</h1>
                  <table id="user_templates_table">
                    <tbody>
                      <tr>
                        <th>Template Name</th>
                        <th>Cost</th>
                      </tr>
                      {this.state.usr_templates_list.length === 0 &&
                        <tr>
                          <td>No Data</td>
                          <td>No Data</td>
                        </tr>
                      }
                      {this.state.usr_templates_list.map((utl) => (
                        <tr>
                          <td>{utl.title}</td>
                          <td>{utl.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <button id='close_templates_form' type="submit" onClick={this.closetemplates}>Close</button>
                </div>
              </div> */}
            {/* </div> */}
            <div >
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  }
}

export default Users;