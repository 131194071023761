import React, { Component } from 'react';
import axios from 'axios';
import Logo from '../assets/images/JBlogo.png'
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Landingpage extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "", userloggedin: false };
  }
  componentDidMount() {
  }
  handleSubmit(event) {
    event.preventDefault();
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    axios.get(globaladdress + 'teamlogin?key=' + globalkey + '&username=' + username + '&password=' + password + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then(res => res.data)
      .then((res) => {
        if (res.length === 1) {
          window.localStorage.setItem("isLoggedin", true);
          window.localStorage.setItem("userid", res[0].tdId);
          window.localStorage.setItem("firstname", res[0].firstName);
          window.localStorage.setItem("lastname", res[0].lastName);
          window.localStorage.setItem("username", res[0].userName);
          window.localStorage.setItem("email", res[0].email);
          window.localStorage.setItem("role", res[0].role);
          window.localStorage.setItem("employee_id", res[0].employee_id);
          window.localStorage.setItem("employee_tid", res[0].employee_tid);
          window.localStorage.setItem("employee_mobile", res[0].mobile);
          window.localStorage.setItem("tId", res[0].tId);
          window.localStorage.setItem("session_token", res[0].token);
          window.location.href = "/dashboard";
        } else {
          toast.warning("Invalid Credentials! Please contact admin!")
        }
      })
  }
  render() {
    return (
      <div class="landingpage-container">
        <div class="login-container">
          <div>
            <img id="" className="col-md-12 brand" src={Logo} alt='Jagbandhu' style={{ maxWidth: "190px" }} />
          </div>
          <br />
          <div>
            <h4>Login</h4>
          </div>
          <div className='login_form_container'>
            <form>
              <div class="mb-3">
                <label for="email" class="form-label">Email address <input type="text" class="form-control input-username" id="username" placeholder="Enter email" /></label>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password<input type="password" class="form-control input-password" id="password" placeholder="Enter password" /></label>
              </div>
              <br />
              <button className="btn login_btn" onClick={this.handleSubmit}>Login</button>
            </form>
            <br />
          </div>
        </div>
      </div>
    );
  }
};
export default Landingpage;