import React, { Component } from 'react';
import ReactLoading from "react-loading";
import Navbar from "../../components/Navbar";
import Header from '../../components/Header/header';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { globalkey, globaladdress, s_key, s_secret } from '../variables';
import axios from "axios";
import { toast } from 'react-toastify';
import LayerDetails from "../Resources/Invitations/InvitationLayerComponent";
import withParams from '../../components/React_Dom_Router/withParams';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FaPlus, FaRupeeSign } from "react-icons/fa";
import LogoWhite from '../assets/images/logo5.svg';
import { AiOutlineDownload } from "react-icons/ai";
import { Modal as Modal1, Button as Button1 } from 'antd';
class Products extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            selectedProductCategoryId: '',
            showProductsList: false,
            imagePreviewUrl: '',
            editMode: false,
            searchText: '',
            DataisLoaded: false,
            showModal: false,
            showModal2: false,
            show_add_question_form: false,
            questionType: '',
            options: [],
            newOption: '',
            image: null,
            imageFile: null,
            finalprice: null,
            fontList: [],
            categories: [],
            templateTags: [],
            selectedTags: [],
            finishedimage: null,
            finishedimagefile: null,
            templates: [],
            selectedTempIndex: null,
            user_role_acc: window.localStorage.getItem("role"),
            selectedTemplate: {
                user_role_acc: window.localStorage.getItem("role"),
                templateid: null,
                templatename: null,
                status: null,
                full: null,
                category: null,
                font: null,
                text_length: null,
                price: null,
                userid: null,
                description: null,
                text_width: null,
            },
            currentPage: 1,
            itemsPerPage: 20,
            // selectedTemplate: {
            //     user_role_acc: null,
            //     templateid: null,
            //     templatename: null,
            //     status: null,
            //     full: null,
            //     category: null,
            //     font: null,
            //     price: null,
            //     text_length: null,
            //     text_width: null,
            //     userid: null,
            //     description: null,
            // },
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClose2 = this.handleClose2.bind(this);
        this.inputcheck = this.inputcheck.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.uploadImage2 = this.uploadImage2.bind(this);
        this.submitInvitation = this.submitInvitation.bind(this);
        this.validitycheck = this.validitycheck.bind(this);
        this.selectInvitation = this.selectInvitation.bind(this);
        this.pricechange = this.pricechange.bind(this);
        this.getTotalPages = this.getTotalPages.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.editProductListDetails = this.editProductListDetails.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.uploadPlimage = this.uploadPlimage.bind(this);

    }

    handleCancel = () => {
        this.setState({ showModal2: false });
    };
    handleInputChange(e, field) {
        const { selectedTemplate } = this.state;
        selectedTemplate[field] = e.target.value;
        this.setState({ selectedTemplate });
    }
    handleSearch = (event) => {
        this.setState({ searchText: event.target.value, currentPage: 1 });
    };
    getTotalPages() {
        const { templates, itemsPerPage, searchText } = this.state;
        const filteredList = templates.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.category.toLowerCase().includes(searchText.toLowerCase()) ||
                item.price.toLowerCase().includes(searchText.toLowerCase()) ||
                this.checkTags(item.label, searchText));
        return Math.ceil(filteredList.length / itemsPerPage);
    }
    checkTags(label, searchText) {
        if (!Array.isArray(label)) {
            return false;
        }
        const tags = label.map(tag => tag.label);
        const regex = new RegExp(searchText.toLowerCase(), 'i');
        return tags.some(tag => regex.test(tag.toLowerCase()));
    }
    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };
    handlePrevPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: currentPage - 1 });
        }
    };
    handleNextPage = () => {
        const { currentPage } = this.state;
        const totalPages = this.getTotalPages();
        if (currentPage < totalPages) {
            this.setState({ currentPage: currentPage + 1 });
        }
    };
    handleGoToPage = (page) => {
        if (page >= 1 && page <= this.getTotalPages()) {
            this.setState({ currentPage: page });
        }
    };
    getTotalPages = () => {
        return Math.ceil(this.state.templates.length / 20);
    };

    validitycheck() {
        var count = 0;
        var inputlist = document.getElementsByClassName('special-input');
        for (var i = 0; i < inputlist.length; i++) {
            var alertmsg = inputlist[i].parentNode.querySelector('.validation-alert');
            if (!inputlist[i].classList.contains('hastext')) {
                if (!alertmsg.classList.contains('invalid')) alertmsg.classList.add('invalid');
                count = count + 1;
            }
            else {
                if (alertmsg.classList.contains('invalid')) alertmsg.classList.remove('invalid');
            };
        }
        if (!this.state.image) {
            document.getElementById('back_image_valid').classList.add('invalid');
            count = count + 1
        } else {
            document.getElementById('back_image_valid').classList.remove('invalid');
        }
        if (!this.state.finishedimage) {
            document.getElementById('full_image_valid').classList.add('invalid');
            count = count + 1;
        } else {
            document.getElementById('full_image_valid').classList.remove('invalid')
        }
        if (count === 0) {
            toast(true);
            return true;
        } else {
            return false;
        }
    }
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    roleckeck() {
        if (this.state.user_role_acc === "User") {
            document.querySelector("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Viewer") {
            document.getElementById("#create_template_btn").style.display = "none";
        }
        if (this.state.user_role_acc === "Operator") {
            document.getElementById("").style.display = "none";
        }
        if (this.state.user_role_acc === "In Active Role") {
            document.getElementById("").style.display = "none";
        }
    }
    componentDidMount() {
        const { productId } = this.props.params;
        this.state.selectedProductCategoryId = productId
        this.signincheck();
        this.roleckeck();
        let sessionTimeOutAlertShown = false;
        const pcId = this.state.selectedProductCategoryId;
        var responseStatus = "";
        fetch(globaladdress + "gettemplates?key=" + globalkey + '&product_category_id=' + pcId + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                console.log(res.status)
                responseStatus = res.status
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    console.log(responseStatus)
                    if (responseStatus === 204 || responseStatus === "204") {
                        this.setState({
                            DataisLoaded: true,
                        });
                    } else {
                        const res = JSON.parse(resText);
                        this.setState({
                            DataisLoaded: true,
                            templates: res,
                        });
                    }
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "getfonts?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        fontList: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "getcatlkp?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        categories: res,
                    });
                }
            }).catch((error) => {
                alert('Error in fetching data from the server');
            });
        fetch(globaladdress + "gettemplatetags?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTags: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
    }
    handleClose() { this.setState({ showModal: false }) }
    handleShow() { this.setState({ showModal: true }) }
    handleClose2() {
        this.setState({
            showModal2: false,
            selectedTemplate: {
                user_role_acc: window.localStorage.getItem("role"),
                templateid: null,
                templatename: null,
                status: null,
                full: null,
                category: null,
                font: null,
                text_length: null,
                price: null,
                userid: null,
                description: null,
                text_width: null
            },
            selectedTempIndex: null,
        })
    }
    pricechange(e) {
        this.inputcheck(e);
        var price = document.getElementById("invit_price").value;
        var discount = document.getElementById("invit_discount").value;
        if (discount <= 100 && discount >= 0 && price) {
            var finalprice = parseInt(price) * (100 - parseInt(discount)) / 100;
            this.setState({
                finalprice: finalprice
            })
        }
    }
    inputcheck(e) {
        var el = e.currentTarget;
        var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
        if (el.value !== '' && !el.classList.contains('hastext')) {
            el.classList.add('hastext')
            if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
        } else if (el.value === '' && el.classList.contains('hastext')) {
            el.classList.remove('hastext')
            alertmsg.add('invalid')
        }
    }
    uploadImage2(e) {
        var objurl = URL.createObjectURL(e.target.files[0]);
        this.setState({ finishedimage: objurl, finishedimagefile: e.target.files[0] });
        var alertmsg = document.getElementById('full_image_valid').classList;
        if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    }
    uploadImage(e) {
        var objurl = URL.createObjectURL(e.target.files[0]);
        this.setState({ image: objurl, imageFile: e.target.files[0] });
        var alertmsg = document.getElementById('back_image_valid').classList;
        if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
    }
    submitInvitation() {
        var bool = this.validitycheck();
        if (bool) {
            var title = document.getElementById("invit_title").value;
            var description = document.getElementById("invit_desc").value;
            var price = document.getElementById("invit_price").value;
            var discount = document.getElementById("invit_discount").value;
            var category = document.getElementById("invit_cat").value;
            var productCategory = this.state.selectedProductCategoryId;
            var font = document.getElementById("invit_font").value;
            var selectedTags = this.state.selectedTags;
            var selTags = selectedTags.map(tag => tag.value).join(',');
            var formdata = new FormData();
            formdata.append("title", title);
            formdata.append("description", description);
            formdata.append("price", price);
            formdata.append("offer_percentage", discount);
            formdata.append("offer_price", this.state.finalprice);
            formdata.append("category", category);
            formdata.append("productCategory", productCategory);
            formdata.append("font", font);
            formdata.append("userid", window.localStorage.getItem("userid"));
            formdata.append("back_image", this.state.imageFile);
            formdata.append("display", this.state.finishedimagefile);
            formdata.append(`tag`, selTags);
            axios.post(globaladdress + 'addtemplate?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }

                    if (res.data === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    selectInvitation(e) {

        if (this.state.selectedProductCategoryId != 1 || this.state.selectedProductCategoryId != "1") {
            this.setState({ showProductsList: !this.state.showProductsList });
            var unselect = document.getElementsByClassName('selected');
            if (unselect.length !== 0) unselect[0].classList.remove('selected');
            e.currentTarget.classList.add('selected');
            var tid = e.currentTarget.dataset.templid;
            var index = this.state.templates.findIndex(x => x.tId == tid);
            const seltemp = {
                templateid: this.state.templates[index].tId,
                templatename: this.state.templates[index].title,
                status: this.state.templates[index].status,
                full: this.state.templates[index].finished_image,
                category: this.state.templates[index].category,
                font: this.state.templates[index].font,
                text_length: this.state.templates[index].text_length,
                price: this.state.templates[index].price,
                userid: this.state.templates[index].team_mem_id,
                description: this.state.templates[index].description,
                text_width: this.state.templates[index].text_width,
            }
            this.setState({ selectedTemplate: seltemp, selectedTempIndex: tid })

            return;
        } else {
            console.log("385");
            var unselect = document.getElementsByClassName('selected');
            if (unselect.length !== 0) unselect[0].classList.remove('selected');
            e.currentTarget.classList.add('selected');
            var tid = e.currentTarget.dataset.templid;
            var index = this.state.templates.findIndex(x => x.tId == tid);
            console.log("selected template id: " + this.state.templates[index].tId);
            const seltemp = {
                templateid: this.state.templates[index].tId,
                templatename: this.state.templates[index].title,
                status: this.state.templates[index].status,
                full: this.state.templates[index].finished_image,
                category: this.state.templates[index].category,
                font: this.state.templates[index].font,
                text_length: this.state.templates[index].text_length,
                price: this.state.templates[index].price,
                userid: this.state.templates[index].team_mem_id,
                description: this.state.templates[index].description,
                text_width: this.state.templates[index].text_width,
            }


            this.setState({ selectedTemplate: seltemp, selectedTempIndex: tid, showModal2: true })
            console.log("selected" + JSON.stringify(seltemp));
        }
    }
    updateSelectedTemplate = (updatedTemplate) => {
        this.setState({
            selectedTemplate: updatedTemplate,
        });
    };
    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    }
    toogleAddQuestion = () => {
        this.setState({
            show_add_question_form: !this.state.show_add_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
        });
    }
    handleOptionChange = (e) => {
        this.setState({ newOption: e.target.value });
    };
    getSuggestedTags = () => {
        const { newOption, templateTags } = this.state;
        return templateTags.filter(tag => tag.label.includes(newOption));
    };
    addOption = () => {
        const { newOption, options } = this.state;
        if (newOption.trim() !== '') {
            this.setState({
                options: [...options, newOption],
                newOption: '',
            });
        }
    };
    addQuestion(event) {
        event.preventDefault();
        const newOption = this.state.newOption;
        const updatedOptions = [...this.state.options, newOption];
        const nonEmptyOptions = updatedOptions.filter(option => option.trim() !== "");
        const serializedOptions = nonEmptyOptions.map(option => `"${option.trim()}"`).join(',');
        this.setState({
            show_question: !this.state.show_question,
            options: updatedOptions,
            newOption: '',
        });
        var dataset = {
            tag: serializedOptions,
        };
        try {
            const queryString = Object.keys(dataset)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dataset[key]))
                .join('&');
            axios.post(`${globaladdress}addtemplatetags?${queryString}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("Tags added successfully.");
                            window.location.reload();
                        }
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong while adding a new Tag!\nPlease try again later.");
        }
    }
    handleQuestionTypeChange = (e) => {
        this.setState({
            questionType: e.target.value,
            options: e.target.value === 'options' ? [] : this.state.options,
        });
    };
    editProductListDetails = (e) => {
        e.preventDefault();
        const r5 = document.getElementById('invit_cat').value;
        const r6 = document.getElementById('invit_font').value;
        const r7 = this.state.selectedTempIndex;
        const { templatename, status, price, userid, description } = this.state.selectedTemplate;
        if (this.state.imageFile) {
            var formdata = new FormData();
            formdata.append('title', templatename);
            formdata.append('status', status);
            formdata.append('category', r5);
            formdata.append('font', r6);
            formdata.append('price', price);
            formdata.append('description', description);
            formdata.append('userid', userid);
            formdata.append('templateId', r7);
            formdata.append('image', this.state.imageFile);
        } else {
            var formdata = new FormData();
            formdata.append('title', templatename);
            formdata.append('status', status);
            formdata.append('category', r5);
            formdata.append('font', r6);
            formdata.append('price', price);
            formdata.append('description', description);
            formdata.append('userid', userid);
            formdata.append('templateId', r7);
        }

        axios.post(globaladdress + 'updateproductlistdetails?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
            })
            .then((resData) => {
                if (resData === "Session timeOut!") {
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    toggleEditMode = () => {
        this.setState(prevState => ({
            editMode: !prevState.editMode
        }));
    }
    toggleProductsForm = () => {
        var role = window.localStorage.getItem('role');
        if (role !== "Admin") {
            toast.warning("You are not authorized to perform this action!");
        } else {
            this.setState({ showProductsList: !this.state.showProductsList });
        }
    }
    uploadPlimage = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Create a FileReader to read the image
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    imageFile: file,
                    imagePreviewUrl: reader.result  // Store the data URL for preview
                });
            };
            // Read the file as a data URL
            reader.readAsDataURL(file);
        }
    }

    render() {
        const { showModal2 } = this.state;

        const { templates, searchText, DataisLoaded, currentPage, itemsPerPage, } = this.state;
        const filteredList = templates.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.category.toLowerCase().includes(searchText.toLowerCase()) ||
                item.price.toLowerCase().includes(searchText.toLowerCase()) ||
                this.checkTags(item.label, searchText)
        );
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
        const tagOptions = this.state.templateTags.map(item => ({ value: item.value, label: item.label }));
        const { show_add_question_form, newOption } = this.state;
        const suggestedTags = this.getSuggestedTags();
        const isSubmitDisabled = suggestedTags.length > 0;
        return (
            <>
                <div className="container body">
                    <div className='main_container'>
                        <div className='col-md-3 left_col'>
                            <Navbar username={localStorage.username} />
                        </div>
                        <Header />
                        <div className='right_col'>
                            <div className='container'>
                                <div className='content'>
                                    <h1>Product's list</h1>
                                    <div className="button-to-right" id="create_template_btn">
                                        <button className="btn btn-primary" variant="primary" onClick={this.handleShow} >
                                            <i className="fa fa-plus"> Create Template</i>
                                        </button>
                                    </div>
                                    <button className='add_questions-button' onClick={this.toogleAddQuestion}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <FaPlus style={{ background: '#fff', color: '026FBF', fontSize: '20px', borderRadius: '15px', padding: '5px' }} />
                                            <span style={{ marginLeft: '5px' }}>Add Tags</span>
                                        </div>
                                    </button>
                                    <hr />
                                    <div className="main-content">
                                        <div className="">
                                            <input id="templates_search_box" type="text" placeholder="Search product's..." onChange={this.handleSearch} value={searchText} />
                                            <div className="pagination invitation-pagination">
                                                <button
                                                    onClick={() => this.handleGoToPage(1)}
                                                    disabled={currentPage === 1}
                                                    className="invitations_pagination_btns"
                                                >
                                                    &laquo;
                                                </button>
                                                <button
                                                    onClick={this.handlePrevPage}
                                                    disabled={currentPage === 1}
                                                    className="invitations_pagination_btns"
                                                >
                                                    &lsaquo;
                                                </button>
                                                {Array.from({ length: this.getTotalPages() }, (_, index) => index + 1)
                                                    .slice(Math.max(0, currentPage - 3), Math.min(this.getTotalPages(), currentPage + 2))
                                                    .map((page) => (
                                                        <button
                                                            key={page}
                                                            onClick={() => this.handleGoToPage(page)}
                                                            disabled={page === currentPage}
                                                            className={`invitations_pagination_btns ${page === currentPage ? 'active' : ''}`}
                                                        >
                                                            {page}
                                                        </button>
                                                    ))}
                                                {currentPage + 2 < this.getTotalPages() && <span>...</span>}
                                                {currentPage + 1 < this.getTotalPages() && (
                                                    <button
                                                        onClick={() => this.handleGoToPage(this.getTotalPages())}
                                                        className="invitations_pagination_btns"
                                                    >
                                                        {this.getTotalPages()}
                                                    </button>
                                                )}
                                                <button
                                                    onClick={this.handleNextPage}
                                                    disabled={currentPage === this.getTotalPages()}
                                                    className="invitations_pagination_btns"
                                                    style={{ marginLeft: "15px" }}
                                                >
                                                    &rsaquo;
                                                </button>
                                                <button
                                                    onClick={() => this.handleGoToPage(this.getTotalPages())}
                                                    disabled={currentPage === this.getTotalPages()}
                                                    className="invitations_pagination_btns"
                                                >
                                                    &raquo;
                                                </button>
                                                <input
                                                    type="number"
                                                    value={this.state.goToPage}
                                                    onChange={(e) => this.setState({ goToPage: Math.min(Math.max(1, +e.target.value), this.getTotalPages()) })}
                                                    className="go-to-page-input"
                                                />
                                                <button
                                                    onClick={() => this.handleGoToPage(this.state.goToPage)}
                                                    className="go-to-page-btn"
                                                >
                                                    Go
                                                </button>
                                                <span>
                                                    {`Showing ${(currentPage - 1) * 20 + 1}- ${Math.min(currentPage * 20, templates.length)} of ${templates.length} templates (Page ${currentPage} of ${this.getTotalPages()})`}
                                                </span>
                                            </div>

                                            <div className="line-seperator"></div>
                                            <div className="template-list">
                                                <>
                                                    {!DataisLoaded && (
                                                        <div className="loader-back">
                                                            <div className="loader-element">
                                                                <img className="loader-svg" src={LogoWhite} alt='Jagbandhu' />
                                                                <ReactLoading type="spinningBubbles" color="#FFFFFF" height={300} width={150} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {DataisLoaded && templates && currentItems.length > 0 && currentItems.map((templ, index) => (
                                                        <a key={index} data-idx={index} data-templid={templ.tId} onClick={this.selectInvitation} >
                                                            < div className='inviations' >
                                                                <div className='invitation_card'>
                                                                    <div className='invitation_card_bg_container'>
                                                                        <div className='invitation_card_title'>
                                                                            <h5>{templ.title}</h5>
                                                                        </div>
                                                                        <div className='invitation_card_image'>
                                                                            <img
                                                                                className="templ_image"
                                                                                src={templ.finished_image}
                                                                                alt="Invitaion preview"
                                                                            />
                                                                        </div>
                                                                        <div className='invitation_card_footer'>
                                                                            <div className='invitation_card_download_count'>
                                                                                <h5><i><AiOutlineDownload size={15} /></i>{templ.download_count}</h5>
                                                                            </div>
                                                                            <div className='invitation_card_price'>
                                                                                <h5><i><FaRupeeSign size={11} /></i><span style={{ fontWeight: "bold" }}>{templ.price}</span></h5>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>


                                                    ))}
                                                    {DataisLoaded && templates === "no data" && <div><p>No Product's List to select</p></div>}
                                                    {DataisLoaded && templates && currentItems.length === 0 && <div><p>No Product's List to select</p></div>}
                                                </>
                                            </div>
                                        </div>
                                        <div className="vertical-border"></div>
                                    </div>
                                    {this.state.showProductsList && (
                                        <div className="popup">
                                            <div className="popup-inner">
                                                <h2>Edit Product List Details</h2>
                                                <hr />
                                                <form onSubmit={this.editProductListDetails}>
                                                    <div >
                                                        {!this.state.editMode && (
                                                            <>
                                                                <div className="product_list_profile_details" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                                    <div>
                                                                        <p><strong>Template Name: </strong>{this.state.selectedTemplate.templatename}</p>
                                                                        <p><strong>Status: </strong>{this.state.selectedTemplate.status}</p>
                                                                        <p><strong>Category: </strong>{this.state.selectedTemplate.category}</p>
                                                                        <p><strong>Font: </strong>{this.state.selectedTemplate.font}</p>
                                                                        <p><strong>Price: </strong>{this.state.selectedTemplate.price}</p>
                                                                        <p><strong>Description: </strong>{this.state.selectedTemplate.description}</p>
                                                                    </div>
                                                                    <div >
                                                                        <p style={{ display: 'inline-grid' }}>
                                                                            <img
                                                                                style={{ width: "20rem", height: "20rem" }}
                                                                                src={this.state.imagePreviewUrl || `data:image/jpeg;base64,${this.state.selectedTemplate.full}`}
                                                                                alt="Full Image"
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {this.state.editMode && (
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                                    <div>
                                                                        <label>Title: </label>
                                                                        <input id="r1" type="text" value={this.state.selectedTemplate.templatename} onChange={(e) => this.handleInputChange(e, 'templatename')} />
                                                                        <label>Status: </label>
                                                                        <input id="r2" type="text" value={this.state.selectedTemplate.status} onChange={(e) => this.handleInputChange(e, 'status')} />
                                                                        <label>
                                                                            Choose category:{this.state.selectedTemplate.category}
                                                                        </label>
                                                                        <select id="invit_cat" className="special-input" onChange={this.inputcheck}>
                                                                            <option>Select</option>
                                                                            {this.state.categories.map((cat) => {
                                                                                return <option key={cat.clkpId} value={cat.categoryName} > {cat.categoryName} </option>;
                                                                            })}
                                                                        </select>
                                                                        <label>Font: {this.state.selectedTemplate.font}</label>
                                                                        <select id="invit_font" className="special-input" onChange={this.inputcheck}>
                                                                            <option >Select</option>
                                                                            {this.state.fontList.map((font) => {
                                                                                return <option key={font.id} value={font.f_name} > {font.f_name} </option>;
                                                                            })}
                                                                        </select>                                                                        <label>Price: </label>
                                                                        <input id="r3" type="text" value={this.state.selectedTemplate.price} onChange={(e) => this.handleInputChange(e, 'price')} />
                                                                        <label>Description: </label>
                                                                        <textarea id="r4" type="text" value={this.state.selectedTemplate.description} onChange={(e) => this.handleInputChange(e, 'description')} />
                                                                    </div>
                                                                    <div className='product_list_profile_image'>
                                                                        <p style={{ display: 'inline-grid' }}>
                                                                            <img
                                                                                style={{ width: "20rem", height: "20rem" }}
                                                                                src={this.state.imagePreviewUrl || `data:image/jpeg;base64,${this.state.selectedTemplate.full}`}
                                                                                alt="Full Image"
                                                                            />
                                                                            <label
                                                                                htmlFor="file-upload"
                                                                                style={{
                                                                                    width: "20rem",
                                                                                    color: "#fff",
                                                                                    background: "#467dff",
                                                                                    border: "none",
                                                                                    borderRadius: "0px 0px 5px 5px",
                                                                                    display: "block",
                                                                                    textAlign: "center",
                                                                                    lineHeight: "2rem",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                Image Upload
                                                                            </label>
                                                                            <input
                                                                                id="file-upload"
                                                                                type="file"
                                                                                accept="image/*"
                                                                                onChange={this.uploadPlimage}
                                                                                style={{ display: "none" }}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='addFAQspopupform'>
                                                        {!this.state.editMode && (
                                                            <button type="button" onClick={this.toggleEditMode}>Edit</button>
                                                        )}
                                                        {this.state.editMode && (
                                                            <button type="submit">Send</button>
                                                        )}
                                                        <button
                                                            className="close-btn"
                                                            onClick={this.toggleProductsForm}>
                                                            Close form
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                    <div className="Update_layers_modal" id="Update_layers_modal_id">
                                        {/* <Button1 type="primary" onClick={this.showLoading}>
                                            Open Modal
                                        </Button1> */}
                                        <Modal1 dialogClassName="template_det_modal" show={this.state.showModal2} onHide={this.handleClose2} backdrop="static" keyboard={false} destroyOnClose={true}

                                            width={1500}
                                            style={{ top: 30, height: '797px', overflow: 'hidden' }}
                                            styles={{ height: '600px', overflowY: 'scroll' }}
                                            centered
                                            footer={
                                                <Button1 type="primary" onClick={this.showLoading}>
                                                    Reload
                                                </Button1>
                                            }
                                            open={showModal2}
                                            onCancel={this.handleCancel}
                                        >

                                            {this.state.selectedTempIndex &&
                                                <LayerDetails template={this.state.selectedTemplate} updateSelectedTemplate={this.updateSelectedTemplate} updateTemplatePrice={this.updateTemplatePrice} handleClose2={this.handleClose2} />
                                            }
                                        </Modal1>
                                        {/* <Modal dialogClassName="template_det_modal" show={this.state.showModal2} onHide={this.handleClose2} backdrop="static" keyboard={false} >
                                            <div>
                                                <h1>Model</h1>
                                            </div>
                                            <Modal.Header>
                                                <Modal.Title>Template Details</Modal.Title>
                                                <button type="button" className="btn-close" aria-label="Close" onClick={this.handleClose2} >
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {this.state.selectedTempIndex &&
                                                    <LayerDetails template={this.state.selectedTemplate} updateSelectedTemplate={this.updateSelectedTemplate} updateTemplatePrice={this.updateTemplatePrice} handleClose2={this.handleClose2} />
                                                }
                                                {!this.state.selectedTempIndex && <p>No Template Selected</p>}
                                            </Modal.Body>
                                        </Modal> */}
                                    </div>
                                    <Modal show={this.state.showModal} onHide={this.handleClose} backdrop="static" keyboard={false} >
                                        <Modal.Header>
                                            <Modal.Title>Create New Invitation</Modal.Title>
                                            <button type="button" className="btn-close" aria-label="Close" onClick={this.handleClose} >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="col-md-12">
                                                <div className="invitation-form">
                                                    <div className="form-field">
                                                        <input id="invit_title" className="special-input" onChange={this.inputcheck} />
                                                        <label className="special-lable" id="special-lable_title" > Title * </label>
                                                        <span className="validation-alert" > Title cannot be empty </span>
                                                    </div>
                                                    <div className="form-field">
                                                        <textarea id="invit_desc" onChange={this.inputcheck} > </textarea>
                                                        <label className="special-lable" id="special-lable_description" > Description * </label>
                                                        <span className="validation-alert" > Description cannot be empty </span>
                                                    </div>
                                                    <div className="priceRow">
                                                        <div className="form-field col-md-4">
                                                            <input type="number" max-length="3" id="invit_price" className="special-input" onChange={this.pricechange} />
                                                            <label className="special-lable" id="special-lable_price" > Price * </label>
                                                            <span className="validation-alert" > Price cannot be empty </span>
                                                        </div>
                                                        <div className="form-field col-md-4">
                                                            <input type="number" max-length="2" min={0} max={100} id="invit_discount" className="special-input" onChange={this.pricechange} />
                                                            <label className="special-lable" id="special-lable_discount">Discount* </label>
                                                            <span className="validation-alert">Price cannot be empty</span>
                                                        </div>
                                                        <div className="form-field col-md-4">
                                                            <p>Final Price: {this.state.finalprice}</p>
                                                        </div>
                                                    </div>
                                                    <div className="form-field">
                                                        <select id="invit_cat" className="special-input" onChange={this.inputcheck}>
                                                            <option>Select</option>
                                                            {this.state.categories.map((cat) => {
                                                                return <option key={cat.clkpId} value={cat.categoryName} > {cat.categoryName} </option>;
                                                            })}
                                                        </select>
                                                        <label className="special-lable">Category *</label>
                                                        <span className="validation-alert">Please select a category</span>
                                                    </div>
                                                    <div className="form-field">
                                                        <select id="invit_font" className="special-input" onChange={this.inputcheck}>
                                                            <option >Select</option>
                                                            {this.state.fontList.map((font) => {
                                                                return <option key={font.id} value={font.f_name} > {font.f_name} </option>;
                                                            })}
                                                        </select>
                                                        <label className="special-lable">Font *</label>
                                                        <span className="validation-alert">Please select a font</span>
                                                    </div>
                                                    <div className="choose_tags">
                                                        <Select defaultValue={[]} isMulti name="tags" options={tagOptions} className="basic-multi-select" id="selected-tags" classNamePrefix="select" placeholder="Select tags ..." onChange={this.handleTagChange} />
                                                    </div>
                                                    <br></br>
                                                    <div className="form-field">
                                                        <label className="image-lable" > Choose Background Image
                                                            <input id="invit_image" className="image-input" type="file" accept="image/*" onChange={this.uploadImage} />
                                                        </label>
                                                        <img className="uploaded-image-preview" src={this.state.image} alt="Nothing Selected" />
                                                        <span id="back_image_valid" className="validation-alert" > Please select a backgound Image </span>
                                                    </div>
                                                    <div className="form-field">
                                                        <label className="image-lable" > Choose Finished Image
                                                            <input id="invit_image_finished" className="image-input" type="file" accept="image/*" onChange={this.uploadImage2} />
                                                        </label>
                                                        <img className="uploaded-image-preview" src={this.state.finishedimage} alt="Nothing Selected" />
                                                        <span id="full_image_valid" className="validation-alert" > Please select a display image </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={this.submitInvitation} >
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div>
                                        <div className='survey_page_add_question_form_container'>
                                            {show_add_question_form && (
                                                <div className="edit_app_notes">
                                                    <div className="add_tags_inner">
                                                        <h2>Create Tags</h2>
                                                        <br></br>
                                                        <form onSubmit={this.addQuestion}>
                                                            <div>
                                                                <input className="add_tag_input_field" placeholder='Enter tag' value={newOption} onChange={this.handleOptionChange} />
                                                                <br />
                                                                <br />
                                                                <div>
                                                                    <div className='add_question_label'>
                                                                        <ul className="tag-grid">
                                                                            {suggestedTags.map(tag => (
                                                                                <li className="tag_lables" key={tag.value}>#{tag.label}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className='editreleasenotespopupform'>
                                                                <button type="submit" disabled={isSubmitDisabled}>Submit</button>
                                                                <button className="close-btn" onClick={this.toogleAddQuestion} style={{ float: 'right' }}>
                                                                    Close form
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default withParams(Products);