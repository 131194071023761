import React from "react";
import $ from "jquery";
import "./Navbar.css";
import { Nav } from "./NavbarEements";
import StockPNG from './Stock.png'
// import { FaEnvelope, FaUsers, FaUserPlus, FaIdCard, FaUserCircle, FaUserTie, FaTable, FaExclamationTriangle, FaShoppingBag } from "react-icons/fa";
// import { BiMenu, BiLogOut } from "react-icons/bi";
// import { AiOutlineSwap } from "react-icons/ai";
const Navbar = () => {
  // var role = window.localStorage.getItem("role");
  // if(role === "User"){
  //   document.querySelector("#side_nav_members").style.display = "none";
  //   document.querySelector("#side_nav_userreports").style.display = "none";
  //   document.querySelector("#side_nav_userfeedback").style.display = "none";
  // }
  // var signout = () => {
  //   window.localStorage.clear();
  //   window.location.href = "/"
  // }
  // const sidenavshrink = () => {
  //   document.querySelector(".sidenav_cntr").style.display = "none";
  //   document.querySelector(".sidenav_cntr1").style.display = "block";
  //   document.querySelector(".sidenav_cntr1").style.width = "18%";
  //   document.querySelector(".sidenav_cntr1").style.margin = "-25% 0% 0% 0%"
  //   document.querySelector(".side-nav").style.width = "";
  //   document.querySelector(".container2").style.width = "115%";
  //   document.querySelector(".header").style.width = "97%";
  //   document.querySelector(".page-content").style.margin = "0 -13.6vw 0 0";
  //   document.querySelector(".container2").style.width = "117%";
  //   // document.querySelector(".non-editable-details").style.height = "47vh"
  //   // document.querySelector(".profile_container").style.display = "none";
  // }
  // const sidenavshrink1 = () => {
  //   document.querySelector(".sidenav_cntr1").style.display = "none";
  //   document.querySelector(".sidenav_cntr").style.display = "block";
  //   document.querySelector(".side-nav").style.width = "";
  //   document.querySelector(".container2").style.width = "100%";
  //   document.querySelector(".header").style.width = "83.33%"
  //   // document.querySelector(".non-editable-details").style.height = "86vh"
  //   // document.querySelector(".profile_container").style.display = "block";
  // }
  var CURRENT_URL = window.location.href.split('#')[0].split('?')[0],
    $BODY = $('body'),
    $SIDEBAR_MENU = $('#sidebar-menu'),
    $SIDEBAR_FOOTER = $('.sidebar-footer'),
    $LEFT_COL = $('.left_col'),
    $RIGHT_COL = $('.right_col'),
    $NAV_MENU = $('.nav_menu'),
    $FOOTER = $('footer');
  var setContentHeight = function () {
    // reset height
    $RIGHT_COL.css('min-height', $(window).height());
    var bodyHeight = $BODY.outerHeight(),
      footerHeight = $BODY.hasClass('footer_fixed') ? -10 : $FOOTER.height(),
      leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
      contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
    // normalize content
    contentHeight -= $NAV_MENU.height() + footerHeight;
    $RIGHT_COL.css('min-height', contentHeight);
  };
  // check active menu
  $SIDEBAR_MENU.find('a[href="' + CURRENT_URL + '"]').parent('li').addClass('current-page');
  $SIDEBAR_MENU.find('a').filter(function () {
    return this.href == CURRENT_URL;
  }).parent('li').addClass('current-page').parents('ul').slideDown(function () {
    setContentHeight();
  }).parent().addClass('active');
  $SIDEBAR_MENU.find('a').on('click', function (ev) {
    var $openli = $('li.active');
    if ($openli) {
      $openli.removeClass('active active-sm');
      $openli.children('ul').hide();
    }
    var $li = $(this).parent();
    $li.addClass('active');
    $li.children('ul').show();
  });
  const role = localStorage.role;
  // toggle small or large menu

  // recompute content when resizing

  // fixed sidebar

  return (
    <>
      <div className="sidenav_cntr left_col scroll-view">
        <Nav>
          <div id="sidenavmax navbar nav_title">
            <div className="site_title">
              <img id="navimage" src="/static/media/JBlogo.d789b5ac5da71dfce57e.png" alt="Jagbandhu" />
              <p id="jb_sidenav_title">Jagbandhu</p>
            </div>
          </div>
          <div className="profile">
            <div className="profile_pic">
              <img className="img-circle profile_img" src={StockPNG} alt="Jagbandhu" />
            </div>
            <div className="profile_info">
              <span>Welcome!</span>
              <h2>{localStorage.username}</h2>
              <h2>{localStorage.role}</h2>
            </div>
          </div>
          <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
            <div className="menu_section">
              <h3>General</h3>
              <ul className="nav side-menu">
                <li>
                  <a href="/dashboard"><i className="fa fa-home"></i> Dashboard</a>
                </li>

                {/* Conditionally render "User Management" if role is "User" */}
                {role === "User" && (
                  <li>
                    <a><i className="fa fa-edit"></i> User Management <span className="fa fa-chevron-down"></span></a>
                    <ul className="nav child_menu">
                      <li><a href="/user-management/users">Users</a></li>
                      <li><a href="/user-management/userreports">Reports</a></li>
                      <li><a href="/user-management/help">Feedbacks</a></li>
                      <li><a href="/user-management/deletedUsers">Delete Requests</a></li>
                      <li><a href="/user-management/adminDeletedUsers">Deleted Users</a></li>
                    </ul>
                  </li>
                )}

                {/* Render other sections only if role is not "User" */}
                {role !== "User" && (
                  <>
                    <li>
                      <a><i className="fa fa-edit"></i> User Management <span className="fa fa-chevron-down"></span></a>
                      <ul className="nav child_menu">
                        <li><a href="/user-management/users">Users</a></li>
                        <li><a href="/user-management/userreports">Reports</a></li>
                        <li><a href="/user-management/help">Feedbacks</a></li>
                        <li><a href="/user-management/deletedUsers">Delete Requests</a></li>
                        <li><a href="/user-management/adminDeletedUsers">Deleted Users</a></li>
                      </ul>
                    </li>
                    <li>
                      <a><i className="fa fa-desktop"></i> Resources <span className="fa fa-chevron-down"></span></a>
                      <ul className="nav child_menu">
                        <li><a href="/resources/invitations">Products List</a></li>
                        <li><a href="/resources/addrelations">Relations</a></li>
                        <li><a href="/resources/faqs">FAQs</a></li>
                        <li><a href="/resources/products">Products</a></li>
                        <li><a href="/resources/products.lkp">Product Categories</a></li>
                        <li><a href="/resources/addupdates">Update Notes</a></li>
                        <li><a href="/resources/fonts">Fonts</a></li>
                        <li><a href="/resources/jbSurveys">JB Surveys</a></li>
                        <li><a href="/resources/tags">Tags</a></li>
                      </ul>
                    </li>
                    <li>
                      <a><i className="fa fa-table"></i> Team <span className="fa fa-chevron-down"></span></a>
                      <ul className="nav child_menu">
                        <li><a href="/team/members">Members</a></li>
                        <li><a href="/team/profile">Profile</a></li>
                      </ul>
                    </li>
                    <li>
                      <a><i className="fa fa-table"></i> Services <span className="fa fa-chevron-down"></span></a>
                      <ul className="nav child_menu">
                        <li><a href="/services/service-categories">Services</a></li>
                      </ul>
                    </li>
                    <li>
                      <a><i className="fa fa-table"></i> CMR <span className="fa fa-chevron-down"></span></a>
                      <ul className="nav child_menu">
                        <li><a href="/cmr/cmreventmanagement">CMR Event Management</a></li>
                      </ul>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </Nav>
      </div>
    </>
  );
};

export default Navbar;