import React, { Component } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header/header';
import axios from "axios";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import 'react-toastify/dist/ReactToastify.css';
import withNavigation from '../../components/React_Dom_Router/withNavigation';


class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_add_question_form: false,
            ifServiceCategorySelected: false,
            questionType: '',
            options: [],
            newOption: '',
            isEditModalOpen: false,
            editedTag: null,
            editedValue: '',
            editedLabel: '',
            templateTags: [],
            selectedTags: [],
            user_role_acc: window.localStorage.getItem("role"),
            searchQuery: '',
            searchText: '',
            currentPage: 1,
            itemsPerPage: 20,
        };

        // Bind the addQuestion method
        this.getTotalPages = this.getTotalPages.bind(this);

        this.addQuestion = this.addQuestion.bind(this);
    }
    getTotalPages() {
        const { templateTags, itemsPerPage, searchText } = this.state;
        const filteredList = templateTags.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase())
        );
        return Math.ceil(filteredList.length / itemsPerPage);
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    handlePrevPage = () => {
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: currentPage - 1 });
        }
    };

    handleNextPage = () => {
        const { currentPage } = this.state;
        const totalPages = this.getTotalPages();
        if (currentPage < totalPages) {
            this.setState({ currentPage: currentPage + 1 });
        }
    };
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
    }
    componentDidMount() {
        this.signincheck();
        let sessionTimeOutAlertShown = false;
        fetch(globaladdress + "getjbservicecategories?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        templateTags: res,
                    });
                }
            }).catch((error) => {
                console.error("Fetch error: ", error);
            });

    }
    handleTagChange = (selectedTags) => {
        this.setState({ selectedTags });
    }
    toogleAddQuestion = () => {
        this.setState({
            show_add_question_form: !this.state.show_add_question_form,
            question: '',
            questionType: '',
            options: [],
            newOption: '',
        });
    }
    getSuggestedTags = () => {
        const { newOption, templateTags } = this.state;
        return templateTags.filter(tag => tag.title.includes(newOption));
    };
    handleOptionChange = (e) => {
        this.setState({ newOption: e.target.value });
    };
    handleLabelChange = (e) => {
        const newLabel = e.target.value;
        const isLabelExists = this.state.templateTags.some(tag => tag.label === newLabel);

        this.setState({
            editedLabel: newLabel,
            isLabelExists: isLabelExists
        });
    };
    addOption = () => {
        const { newOption, options } = this.state;
        if (newOption.trim() !== '') {
            this.setState({
                options: [...options, newOption],
                newOption: '',
            });
        }
    };
    addQuestion(event) {
        event.preventDefault();
        const newOption = this.state.newOption;
        const updatedOptions = [...this.state.options, newOption];
        const nonEmptyOptions = updatedOptions.filter(option => option.trim() !== "");
        const serializedOptions = nonEmptyOptions.map(option => `"${option.trim()}"`).join(',');
        console.log("newOption: " + newOption);
        console.log("updatedOptions: " + updatedOptions);

        this.setState({
            show_question: !this.state.show_question,
            options: updatedOptions,
            newOption: '',
        });

        var dataset = {
            tag: serializedOptions,
        };
        try {
            axios.post(`${globaladdress}addjbservicecategories?title=${newOption}&key=${globalkey}&token=${localStorage.session_token}&unq_key=${s_key}&secret=${s_secret}&tsId=${localStorage.tId}`)
                .then(response => {
                    if (response.status === 201) {
                        if (response.data === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else {
                            alert("Service category added successfully.");
                            window.location.reload();
                        }
                    } else {
                        alert(`Internal server error \n Error Status code: ${response.status}`);
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error("Axios error:", error);
                    alert("Something went wrong while adding a new Tag!\nPlease try again later.");
                });
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong while adding a new Tag!\nPlease try again later.");
        }
        alert("Tags added successfully")
    }
    handleServiceCategoryClick = (serviceId) => {
        alert('ServiceId ID:'+serviceId);
        this.setState({ifServiceCategorySelected: true});
        this.props.navigate(`/services/service-categories/selected/${serviceId}`);


    }
    render() {
        const { show_add_question_form, newOption } = this.state;
        const suggestedTags = this.getSuggestedTags();
        const isSubmitDisabled = suggestedTags.length > 0;
        const { isEditModalOpen, editedLabel } = this.state;
        const {
            templateTags,
            searchText,
            DataisLoaded,
            currentPage,
            itemsPerPage,
        } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const filteredList = templateTags.filter(
            (item) =>
                item.title.toLowerCase().includes(searchText.toLowerCase())
        );




        const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
        return <>
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    <Header />
                    <div className='right_col'>
                        <div className="services_category_main_container" style={{ minHeight: "95vh" }}>
                            <div className="services_category_header" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                <h1 className="services_category_header_title">Services</h1>
                                <button className="services_category_header_button" style={{ float: "right", margin: "50px 15px 5px 15px", padding: "10px" }} onClick={this.toogleAddQuestion}>Add Service Categories</button>
                            </div>
                            <hr />
                            <div className="services_category_body">
                                <div className="">
                                    <input
                                        id="templates_search_box"
                                        type="text"
                                        placeholder="Search templates..."
                                        onChange={this.handleInputChange}
                                        value={searchText}
                                    />
                                    <div className="pagination invitation-pagination">
                                        <button
                                            onClick={this.handlePrevPage}
                                            disabled={currentPage === 1}
                                            className="invitations_pageination_btns"
                                        >
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <span>
                                            {`Showing ${(currentPage - 1) * 20 + 1}- ${Math.min(currentPage * 20, templateTags.length)} of ${templateTags.length} categories (Page ${currentPage} of ${this.getTotalPages()})`}
                                        </span>
                                        <button
                                            onClick={this.handleNextPage}
                                            disabled={currentPage === this.getTotalPages()}
                                            style={{ marginLeft: "15px" }}
                                            className="invitations_pageination_btns"
                                        >
                                            <i className="fa fa-arrow-right"></i>
                                        </button>
                                    </div>
                                    <div className="line-seperator"></div>
                                    <div className="product_category_lkp">
                                        <div
                                            className="product_category_lkp_title"
                                            style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-start" }}
                                        >
                                            {currentItems.map((category, index) => (
                                                <button
                                                    className="product_category_lkp_title_click"
                                                    style={{
                                                        background: '#cecece',
                                                        padding: '65px',
                                                        margin: '10px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        border: '0px',
                                                        transition: 'background 0.3s ease',
                                                    }}
                                                    key={index}
                                                    onClick={() => this.handleServiceCategoryClick(category.service_id)}
                                                    onMouseOver={(e) => e.currentTarget.style.background = '#b0b0b0'}
                                                    onMouseOut={(e) => e.currentTarget.style.background = '#cecece'}
                                                >
                                                    {category.title}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="services_category_forms_main_container">
                            <div className='survey_page_add_question_form_container'>
                                {show_add_question_form && (
                                    <div className="edit_app_notes">
                                        <div className="add_tags_inner">
                                            <h2>Create Tags</h2>
                                            <br></br>
                                            <form onSubmit={this.addQuestion}>
                                                <div>
                                                    <input
                                                        className="add_tag_input_field"
                                                        placeholder='Enter tag'
                                                        value={newOption}
                                                        onChange={this.handleOptionChange}
                                                    />
                                                    <br />
                                                    <br />
                                                    <div>
                                                        <div className='add_question_label'>
                                                            <ul className="tag-grid">
                                                                {suggestedTags.map(tag => (
                                                                    <li className="tag_lables" key={tag.value}>#{tag.label}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='editreleasenotespopupform'>
                                                    <button type="submit" disabled={isSubmitDisabled}>Submit</button>
                                                    <button className="close-btn" onClick={this.toogleAddQuestion} style={{ float: 'right' }}>
                                                        Close form
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}
export default withNavigation(Users);