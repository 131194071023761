import React, { Component } from "react";
import Navbar from "../../../components/Navbar";
import Header from '../../../components/Header/header';
import { globalkey, globaladdress, s_key, s_secret } from '../../variables';
import LayerDetails from "./InvitationLayerComponent";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FaPlus, FaEdit, FaBars } from "react-icons/fa";
import withNavigation from '../../../components/React_Dom_Router/withNavigation';

class Invitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      DataisLoaded: false,
      productCategoryLkp: [],
      ifProductCatSelected: false,
      user_role_acc: window.localStorage.getItem("role"),
      currentPage: 1,
      itemsPerPage: 20,
    };

    this.getTotalPages = this.getTotalPages.bind(this);
  }

  handleInputChange = (event) => {
    this.setState({ searchText: event.target.value, currentPage: 1 });
  };

  getTotalPages() {
    const { productCategoryLkp, itemsPerPage, searchText } = this.state;
    const filteredList = productCategoryLkp.filter(
      (item) =>
        item.category_name.toLowerCase().includes(searchText.toLowerCase())
    );
    return Math.ceil(filteredList.length / itemsPerPage);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleNextPage = () => {
    const { currentPage } = this.state;
    const totalPages = this.getTotalPages();
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };
  handleGoToPage = (page) => {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.setState({ currentPage: page });
    }
  };
  getTotalPages = () => {
    return Math.ceil(this.state.productCategoryLkp.length / 20);
  };

  signincheck() {
    if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  }

  roleckeck() {
    if (this.state.user_role_acc === "User" || this.state.user_role_acc === "Viewer" || this.state.user_role_acc === "In Active Role") {
      document.getElementById("create_template_btn").style.display = "none";
    }
  }

  componentDidMount() {
    this.signincheck();
    this.roleckeck();
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getproductcategorylkp?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          this.setState({
            DataisLoaded: true,
            productCategoryLkp: res,
          });
        }
      }).catch((error) => {
        console.error("Fetch error: ", error);
      });
  }

  handleProductCategoryClick = (productId) => {
    console.log('Product ID:', productId);
    alert("Product ID selected: " + productId);
    this.setState({ ifProductCatSelected: true });
    this.props.navigate(`/resources/invitations/productlist/${productId}`);
  }

  render() {
    const {
      productCategoryLkp,
      searchText,
      DataisLoaded,
      currentPage,
      itemsPerPage,
    } = this.state;

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredList = productCategoryLkp.filter(
      (item) =>
        item.category_name.toLowerCase().includes(searchText.toLowerCase())
    );
    const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <>
        <div className="container body">
          <div className='main_container'>
            <div className='col-md-3 left_col'>
              <Navbar username={localStorage.username} />
            </div>
            <Header />
            <div className='right_col'>
              <div className='container2'>
                <div className='content'>
                  <h1>Product Categories</h1>
                  <hr />
                  <div className="main-content">
                    <div className="">
                      <input
                        id="templates_search_box"
                        type="text"
                        placeholder="Search templates..."
                        onChange={this.handleInputChange}
                        value={searchText}
                      />
                      <div className="pagination invitation-pagination">
                        <button
                          onClick={() => this.handleGoToPage(1)}
                          disabled={currentPage === 1}
                          className={`invitations_pagination_btns ${currentPage === 1 ? 'disabled' : ''}`}
                        >
                          &laquo;
                        </button>
                        <button
                          onClick={this.handlePrevPage}
                          disabled={currentPage === 1}
                          className={`invitations_pagination_btns ${currentPage === 1 ? 'disabled' : ''}`}
                        >
                          &lsaquo;
                        </button>
                        {Array.from({ length: this.getTotalPages() }, (_, index) => index + 1)
                          .slice(Math.max(0, currentPage - 3), Math.min(this.getTotalPages(), currentPage + 2))
                          .map((page) => (
                            <button
                              key={page}
                              onClick={() => this.handleGoToPage(page)}
                              disabled={page === currentPage}
                              className={`invitations_pagination_btns ${page === currentPage ? 'active' : ''}`}
                            >
                              {page}
                            </button>
                          ))}
                        {currentPage + 2 < this.getTotalPages() && <span>...</span>}
                        {currentPage + 1 < this.getTotalPages() && (
                          <button
                            onClick={() => this.handleGoToPage(this.getTotalPages())}
                            className="invitations_pagination_btns"
                          >
                            {this.getTotalPages()}
                          </button>
                        )}
                        <button
                          onClick={this.handleNextPage}
                          disabled={currentPage === this.getTotalPages()}
                          className={`invitations_pagination_btns ${currentPage === this.getTotalPages() ? 'disabled' : ''}`}
                          style={{ marginLeft: "15px" }}
                        >
                          &rsaquo;
                        </button>
                        <button
                          onClick={() => this.handleGoToPage(this.getTotalPages())}
                          disabled={currentPage === this.getTotalPages()}
                          className={`invitations_pagination_btns ${currentPage === this.getTotalPages() ? 'disabled' : ''}`}
                        >
                          &raquo;
                        </button>
                        <input
                          type="number"
                          value={this.state.goToPage}
                          onChange={(e) => this.setState({ goToPage: Math.min(Math.max(1, +e.target.value), this.getTotalPages()) })}
                          className="go-to-page-input"
                        />
                        <button
                          onClick={() => this.handleGoToPage(this.state.goToPage)}
                          className="go-to-page-btn"
                        >
                          Go
                        </button>
                        <span>
                          {`Showing ${(currentPage - 1) * 20 + 1}- ${Math.min(currentPage * 20, productCategoryLkp.length)} of ${productCategoryLkp.length} categories (Page ${currentPage} of ${this.getTotalPages()})`}
                        </span>
                      </div>
                      <div className="line-seperator"></div>
                      <div className="product_category_lkp">
                        <div
                          className="product_category_lkp_title"
                          style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-start" }}
                        >
                          {currentItems.map((category, index) => (
                            <button
                              className="product_category_lkp_title_click"
                              style={{
                                background: '#cecece',
                                padding: '65px',
                                margin: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                border: '0px',
                                transition: 'background 0.3s ease',
                              }}
                              key={index}
                              onClick={() => this.handleProductCategoryClick(category.productId)}
                              onMouseOver={(e) => e.currentTarget.style.background = '#b0b0b0'}
                              onMouseOut={(e) => e.currentTarget.style.background = '#cecece'}
                            >
                              {category.category_name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="vertical-border"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withNavigation(Invitations);
