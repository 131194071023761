import React from "react";
import $ from "jquery";
import { FaEnvelope, FaUsers, FaUserPlus, FaIdCard, FaUserCircle, FaUserTie, FaTable, FaExclamationTriangle } from "react-icons/fa";
import { BiMenu, BiLogOut } from "react-icons/bi";
import { AiOutlineSwap } from "react-icons/ai";
import { globalkey, globaladdress } from '../../page/variables'




class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenancestat: null,
    }
  }
  signincheck() {
    if (!window.localStorage.getItem("isLoggedin")) { window.location.replace("/"); }
  }
  componentDidMount() {
    this.signincheck();

  }
  shrinksidenav() {
    var sidenav = document.getElementsByClassName('side-nav')[0];
    var page = document.getElementsByClassName('page-content')[0];
    var $BODY = $('body'),
      $SIDEBAR_MENU = $('#sidebar-menu');

    // if (sidenav.classList.contains("shrink")) {
    //   sidenav.classList.remove("shrink");
    //   page.classList.remove('shrink');
    // } else {
    //   sidenav.classList.add("shrink");
    //   page.classList.add('shrink');
    // }

    if ($BODY.hasClass('nav-md')) {
      $SIDEBAR_MENU.find('li.active ul').hide();
      $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
    } else {
      $SIDEBAR_MENU.find('li.active-sm ul').show();
      $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
    }

    $BODY.toggleClass('nav-md nav-sm');


  }
  signout() {
    window.localStorage.clear();
    window.location.href = "/"
  }

  render() {
    // console.log(this.state.maintenancestat)
    return <>

      <div className="header top_nav">

        <div className="header_elements">
          <div className="menu-shrink">
            <a className="bars-button" onClick={this.shrinksidenav}><i className="fa fa-bars"></i></a>
          </div>
          <div>
            <h4>JagBandhu - Admin Panel</h4>
          </div>
          <div className="signoutbtndiv">
            <a className="signoutbtn" onClick={this.signout}>Logout <i className="fa fa-power-off"></i></a>
          </div>
        </div>
      </div>
    </>
  };
}


export default Header;