import React, { Component } from "react";
// import Navbar from "./components/Navbar";
import Members from './page/Team/members';
import Users from './page/User_management/users/users';
import Invitations from './page/Resources/Invitations/invitations';
import Landingpage from './page/Login/landingpage';
import DashBoard from './page/Dashboard/dashboard';
import Events from './page/events';
import Datatable from './page/datatable';
import UserReports from './page/User_management/users/userReports';
import Addimage from './page/addImg';
import AddRelations from './page/Resources/addrelations';
import Transactions from './page/Resources/transactions';
import Helpsupport from './page/User_management/help';
import AddUpdates from './page/Resources/add_updates';
import Products from './page/Resources/products';
import DeletedUsers from './page/User_management/deleted_users';
import AdminDeletedUsers from './page/User_management/admin_deleted_users';
import ProductsLkp from './page/Resources/products_lkp';
import FAQs from './page/Resources/FAQs';
import InvitationsExt from "./page/reqst_pages/invitations_ext_page";
import Fonts from './page/Resources/fonts';
import Profile from './page/Team/profile';
import Surveys from './page/Resources/surveys';
import Tags from './page/Resources/tags';
import CmrEventManager from './page/CMR/cmr_event_manager';
import SelectedProductCatList from './page/Resources/selected_product_cat_list';
import Services from './page/Services/service_categories';
import SelectedServiceCategory from './page/Services/selected_service_category';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { apiResponse: "", userloggedin: false };
    }
    callAPI() {
        // fetch("http://192.168.1.23:3003")
        //     .then(res => res.text())
        //     .then(res => this.setState({ apiResponse: res }))
        //     .catch(err => err);

    }
    componentDidMount() {
        this.callAPI();
    }
    render() {
        return (
            <Router>
                <Routes>
                    {/* Landing page */}
                    <Route path='/' element={<Landingpage />} />

                    {/* Dashboard */}
                    <Route path='/dashboard' element={<DashBoard />} />

                    {/* User Management */}
                    <Route path='/user-management/users' element={<Users />} />
                    <Route path='/user-management/userreports' element={<UserReports />} />
                    <Route path='/user-management/help' element={<Helpsupport />} />
                    <Route path='/user-management/deletedUsers' element={<DeletedUsers />} />
                    <Route path='/user-management/adminDeletedUsers' element={<AdminDeletedUsers />} />
                    {/* Resources */}
                    <Route path='/resources/invitations' element={<Invitations />} />
                    <Route path='/resources/invitations/productlist/:productId' element={<SelectedProductCatList />} />
                    <Route path='/resources/addrelations' element={<AddRelations />} />
                    <Route path="/resources/faqs" element={<FAQs />} />
                    <Route path='/resources/products' element={<Products />} />
                    <Route path='/resources/products.lkp' element={<ProductsLkp />} />
                    <Route path='/resources/addupdates' element={<AddUpdates />} />
                    <Route path="/resources/fonts" element={<Fonts />} />
                    <Route path="/resources/jbSurveys" element={<Surveys />} />
                    <Route path="/resources/tags" element={<Tags />} />

                    {/* Team */}
                    <Route path='/team/members' element={<Members />} />
                    <Route path="/team/profile" element={<Profile />} />

                    {/* CMR */}
                    <Route path="/cmr/cmreventmanagement" element={<CmrEventManager />} />
                    {/* Services */}
                    <Route path="/services/service-categories" element={<Services />} />
                    <Route path="/services/service-categories/selected/:serviceId" element={<SelectedServiceCategory/>}/>
                    {/* others */}
                    <Route path='/events' element={<Events />} />
                    <Route path='/datatable' element={<Datatable />} />
                    <Route path='/addimage' element={<Addimage />} />
                    <Route path='/transactions' element={<Transactions />} />
                    <Route path='/invitations.ext' element={<InvitationsExt />} />
                </Routes>
            </Router>
        );
    }
}
export default App;
export const globalkey = "thisisReacturl";