import React, { Component } from "react";
import { globalkey, globaladdress, s_key, s_secret } from '../../variables';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

class LayerDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsrIndex: null,
            firstname: null,
            lastname: null,
            frnid: '',
            famid: '',
            efullname: '',
            edisplayname: '',
            edob: '',
            dobinpt: '',
            emobile1: '',
            efdisplayname: '',
            update_userId: '',
            update_user_mobile: '',
            selectedUser: {
                role: window.localStorage.getItem('role'),
                firstName: null,
                lastName: null,
                cdid: null,
                displayname: null,
                email: null,
                userid: null,
                firstname: null,
                lastname: null,
                status: null,
                mobile: null,
                dob: null,
                show_user_frnd_update_form: false,
                show_user_family_update_form: false,
                show_user_family_update_form1: false,
                selectedOption: '',
                edisplayname: '',
                edob: '',
                // emobile1:'',
            },
            selected_family_member: {
                id: '',
                displayName: '',
                mobile: '',
                gender: '',
                relationName: '',
                dob: '',

            },

            role: window.localStorage.getItem('role'),
            userdeta: [],
            usr_family_mem_list: [],
            usr_frnd_list: [],
            usr_event_list: [],
            usr_inv_event_list: [],
            usr_circle_list: [],
            usr_invitations_list: [],
            usr_templates_list: [],
            user_update_data: [],
        }
        // update user frnd from function
        this.user_frnd_gen_change = this.user_frnd_gen_change.bind(this);
        this.updateusrfrnddetails = this.updateusrfrnddetails.bind(this);
        this.updateusrfamilydetails = this.updateusrfamilydetails.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }
    componentDidMount() {
        let sessionTimeOutAlertShown = false;
        this.setState({ selectedUser: this.props.user });
        fetch(globaladdress + "getuserfamilylist?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_family_mem_list: res,
                    });
                }

                // console.table(this.state.usr_family_mem_list);
            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
        fetch(globaladdress + "getuserfriends?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_frnd_list: res,
                    });
                }

                // console.table(this.state.usr_frnd_list);
            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
        fetch(globaladdress + "getuserevents?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_event_list: res.customer_created_events,
                        usr_inv_event_list: res.customer_invited_events
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch(globaladdress + "getusercircles?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_circle_list: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
        fetch(globaladdress + "getuserinvitations?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_invitations_list: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            })
        fetch(globaladdress + "getusertemplates?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + "&userId=" + this.props.user.cdid)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then((resText) => {
                if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
                    sessionTimeOutAlertShown = true;
                    alert("Session timeOut!");
                    window.localStorage.clear();
                    window.location.href = "/";
                } else {
                    const res = JSON.parse(resText);
                    this.setState({
                        usr_templates_list: res,
                    });
                }

            }).catch((error) => {
                console.error("Fetch error: ", error);
            });
    }
    showForm() {
        document.querySelector(".updateuserform").style.display = "flex";
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector('.showevents').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#closeform3').style.display = "none";
    }
    closeForm() {
        document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    showfamilytree() {
        document.querySelector(".showtree").style.display = "flex";
        document.querySelector('.showfriends').style.display = "none";
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector('.showevents').style.display = "none";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
        document.querySelector('#closeform3').style.display = "none";
        document.querySelector('#showfriendsbtn').style.display = "flex";
    }
    closeForm1() {
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
    }
    showfriends() {
        document.querySelector('.showfriends').style.display = "flex";
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector('.showevents').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    closeFriends() {
        document.querySelector('#closeform3').style.display = "none";
        document.querySelector('#showfriendsbtn').style.display = "flex";
    }
    showeventslist() {
        document.querySelector('.showevents').style.display = "flex";
        document.querySelector('.showfriends').style.display = "none";
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    closeevents() {
        document.querySelector('#closeform7').style.display = "none";
        document.querySelector('#showeventsbtn').style.display = "flex";
        // showeventsbtn
    }
    showinvitationslist() {
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "flex";
        document.querySelector('.showfriends').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector('.showevents').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    closeinvitations() {
        document.querySelector('#closeform5').style.display = "none";
        document.querySelector('#showinvitations').style.display = "flex";
    }
    showtemplateslist() {
        document.querySelector('.showtemplates').style.display = "flex";
        document.querySelector('.showevents').style.display = "none";
        document.querySelector('.showcircles').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showfriends').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    closetemplates() {
        document.querySelector('#closeform6').style.display = "none";
        document.querySelector('#showtemplates').style.display = "flex";
    }
    showusercircleslist() {
        document.querySelector('.showcircles').style.display = "flex";
        document.querySelector('.showfriends').style.display = "none";
        document.querySelector('.showinvitations').style.display = "none";
        document.querySelector('.showtemplates').style.display = "none";
        document.querySelector('.showevents').style.display = "none";
        document.querySelector("#closeform2").style.display = "none";
        document.querySelector('#showfamilytree').style.display = "flex";
        // document.querySelector("#closeform1").style.display = "none";
        document.querySelector('#showusereditfrom').style.display = "block";
    }
    closecircles() {
        document.querySelector('#closeform4').style.display = "none";
        document.querySelector('#showusercircles').style.display = "flex";
    }
    inputcheck(e) {
        var el = e.currentTarget;
        var alertmsg = el.parentNode.querySelector('.validation-alert').classList;
        if (el.value !== '' && !el.classList.contains('hastext')) {
            el.classList.add('hastext')
            if (alertmsg.contains('invalid')) alertmsg.remove('invalid');
        } else if (el.value === '' && el.classList.contains('hastext')) {
            el.classList.remove('hastext')
            alertmsg.add('invalid')
        }
    }
    handleDropdownChange = (event) => {
        const { id, value } = event.target;
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                [id]: value,
            },
        });
    };

    toogle_user_frnd_update_form = (e) => {
        this.setState({ frnid: e.currentTarget.dataset.frndid, edob: e.currentTarget.dataset.edob, selectedOption: e.currentTarget.dataset.selopt, edisplayname: e.currentTarget.dataset.edisplayname, emobile1: e.currentTarget.dataset.emobile1 });
        this.setState({ show_user_frnd_update_form: !this.state.show_user_frnd_update_form });
    };
    toogle_user_family_update_form = (e) => {
        const m_fmId = e.currentTarget.dataset.famid
        const m_displayName = e.currentTarget.dataset.famdisplayname
        const m_mobile = e.currentTarget.dataset.fammobile
        const m_gender = e.currentTarget.dataset.famgender
        const m_relationName = e.currentTarget.dataset.famrelationname
        const m_dob = e.currentTarget.dataset.famdob
        toast.info(m_dob)
        this.setState({
            selected_family_member: {
                id: m_fmId,
                displayName: m_displayName,
                mobile: m_mobile,
                gender: m_gender,
                relationName: m_relationName,
                dob: m_dob
            },
        });
        this.setState({ famid: e.currentTarget.dataset.famid });
        this.setState({ show_user_family_update_form: !this.state.show_user_family_update_form });
    };
    toogle_user_details_update_form_1 = (e) => {
        this.setState({
            show_user_family_update_form1: !this.state.show_user_family_update_form1,
        });
        if (!this.state.show_user_family_update_form1) {
            document.getElementById('user_btns').style.display = "none";
            document.getElementById('close_user_details_btn').style.display = "none";

            // alert(uuserId, existmobile);
        } else {
            document.getElementById('user_btns').style.display = "flex";
            document.getElementById('close_user_details_btn').style.display = "block";


        }

    }
    updateusrfrnddetails(event) {
        event.preventDefault();
        // type:this.state.selectedOption,
        var updatedtset = {
            id: this.state.frnid,
            displayName: document.getElementById('edit_display_name').value,
            dob: document.getElementById('edit_dob').value,
            mobile: document.getElementById('edit_number').value,
            gender: this.state.selectedOption
            // dob: this.state.selectedUser.edob,
            // mobile: this.state.emobile1
        }
        if (updatedtset.id.includes("M")) {
            // alert(JSON.stringify(updatedtset));
            var formdata = new FormData();
            formdata.append('frndId', updatedtset.id);
            formdata.append('displayName', updatedtset.displayName);
            formdata.append('dob', updatedtset.dob);
            formdata.append('mobile', updatedtset.mobile);
            formdata.append('gender', updatedtset.gender);
            if (updatedtset.id) {
                axios.post(globaladdress + 'adminupdatefrnddetails?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                    .then(response => {
                        // alert(JSON.stringify(response));
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.text();
                    })
                    .then((resText) => {
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else if (resText.data === "User Frnd details updated successfully") {
                            window.location.reload();
                        } else {
                            console.error("Error with server")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            } else {
                toast.error("Something is strange please contact Admin!")
            }
        } else {
            toast.info("Customer friend is validated. Further updates will be updated by the customer friend's account")
        }


    }
    formatDate = (dateString) => {
        if (dateString) {
            const parts = dateString.split('-');
            if (parts.length === 3) {
                const [day, month, year] = parts;
                // Reformat the date to "yyyy-mm-dd" format
                return `${year}-${month}-${day}`;
            }
        }
        return ''; // Return an empty string if the date is invalid or not available
    };

    updateusrfamilydetails(event) {
        event.preventDefault();
        var famupdtst = {
            id: this.state.famid,
            displayName: document.getElementById('edit_fam_display_name').value,
            mobile: document.getElementById('edit_fam_Mobile').value,
            dob: document.getElementById('edit_fam_dob').value
        }
        toast.info("Family member Id: " + famupdtst.id);
        toast.info('Displau Name: ' + famupdtst.displayName);
        if (famupdtst.id.includes("M")) {
            // alert(JSON.stringify(famupdtst));
            var formdata = new FormData();
            formdata.append('famdId', famupdtst.id);
            formdata.append('displayName', famupdtst.displayName);
            formdata.append('dob', famupdtst.dob);
            formdata.append('mobile', famupdtst.mobile);
            if (famupdtst.id) {
                axios.post(globaladdress + 'adminupdatefamilydetails?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                    .then(response => {
                        // alert(JSON.stringify(response));
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.text();
                    })
                    .then((resText) => {
                        console.log("Full response:", resText);
                        if (resText === "Session timeOut!") {
                            alert("Session timeOut!");
                            window.localStorage.clear();
                            window.location.href = "/";
                        } else if (resText.data === "User family details updated successfully") {
                            window.location.reload();
                        } else {
                            console.error("error with server")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            } else {
                toast.error("Something is strange please contact Admin!")
            }
        } else {
            toast.info("Customer family member is validated further updates will be updated by the customer family member's account")
        }
    }
    updateUserDetails(e) {
        e.preventDefault();
        var uuserId = e.currentTarget.dataset.ucdid;
        var existmobile = e.currentTarget.dataset.emobile;
        var first_Name, lastName, displayName, mobile, email, dob, gender, maritalstatus, address, district, state, country, status;
        first_Name = document.getElementById("first_Name").value;
        lastName = document.getElementById("lastName").value;
        displayName = document.getElementById("displayName").value;
        mobile = document.getElementById("mobile").value;
        email = document.getElementById("email").value;
        dob = document.getElementById("dob").value;
        gender = document.getElementById("gender").value;
        maritalstatus = document.getElementById("maritalstatus").value;
        address = document.getElementById("address").value;
        district = document.getElementById("district").value;
        state = document.getElementById("state").value;
        country = document.getElementById("country").value;
        status = document.getElementById("status").value;
        if (first_Name && lastName && displayName && mobile && email && dob && gender && maritalstatus) {
            var formdata = new FormData();
            formdata.append('userId', uuserId);
            formdata.append("first_name", first_Name)
            formdata.append("last_name", lastName);
            formdata.append("display_name", displayName);
            formdata.append("mobile", mobile);
            formdata.append('exesting_mobile', existmobile);
            formdata.append("email", email);
            formdata.append("dob", dob);
            formdata.append("gender", gender);
            formdata.append("marital_status", maritalstatus);
            formdata.append("address", address);
            formdata.append("district", district);
            formdata.append("state", state);
            formdata.append("country", country);
            formdata.append("status", status);
            toast.info(JSON.stringify(uuserId))
            axios.post(globaladdress + 'updateuserdetailsadmin?key=' + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId, formdata)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.text();
                })
                .then((resText) => {
                    console.log("Full response:", resText);
                    if (resText === "Session timeOut!") {
                        alert("Session timeOut!");
                        window.localStorage.clear();
                        window.location.href = "/";
                    } else {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            toast.warning("required fields cannot be empty")
        }
    }
    user_frnd_gen_change(event) {
        var option1 = event.currentTarget.value;
        this.setState({ selectedOption: option1 });
    }
    render() {
        return <div className="user_det_view">
            <div className="non-editable-details">
                <div className="user_det_edit_form">
                    <div className="user_preview_sectn">
                        <div className="templ_view_container">
                            <img className="user_image_view" src={this.state.selectedUser.image} alt="User Details" />
                            {this.state.role === "Admin" &&
                                <div>
                                    <button id='showusereditfrom' type="submit" onClick={this.toogle_user_details_update_form_1}>Update User</button>

                                </div>
                            }
                            {this.state.show_user_family_update_form1 && (
                                <div className="edit_app_notes">
                                    <div className="edit_app_notes-inner" id="edit_app_notes-inner_id">
                                        <h2>Update User Details</h2>
                                        <hr />
                                        <form data-emobile={this.state.selectedUser.mobile} data-ucdid={this.state.selectedUser.cdid} onSubmit={this.updateUserDetails}>
                                            <div className="updateuser_form">
                                                <label id="updateuser_form_lable">First Name: </label>
                                                <input className="updateuser_form_input" id="first_Name" placeholder={this.state.selectedUser.firstname} type="text" defaultValue={this.state.selectedUser.firstname} align='right' onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Last Name: </label>
                                                <input className="updateuser_form_input" id="lastName" placeholder={this.state.selectedUser.lastname} type="text" defaultValue={this.state.selectedUser.lastname} onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Display Name: </label>
                                                <input className="updateuser_form_input" id="displayName" placeholder={this.state.selectedUser.displayname} type="text" defaultValue={this.state.selectedUser.displayname} onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Mobile: </label>
                                                <input className="updateuser_form_input" id="mobile" placeholder={this.state.selectedUser.mobile} type="tel" pattern="[0-9]{10}" maxLength={10} defaultValue={this.state.selectedUser.mobile} onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Email: </label>
                                                <input className="updateuser_form_input" id="email" placeholder={this.state.selectedUser.email} type="text" defaultValue={this.state.selectedUser.email} onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Date of Birth: </label>
                                                <input className="updateuser_form_input" id="dob" placeholder={this.state.selectedUser.dob} type="text" defaultValue={this.state.selectedUser.dob} onChange={this.inputcheck} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Address: </label>
                                                <input className="updateuser_form_input" id="address" placeholder={this.state.selectedUser.address} type="text" defaultValue={this.state.selectedUser.address} />
                                                <span className="validation-alert"> * cannot be empty</span>
                                                <br /><br />
                                                <label id="updateuser_form_lable">Gender:
                                                    <select
                                                        disabled
                                                        className="updateuser_form_input"
                                                        id="gender"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.gender}
                                                    >
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </label>
                                                <br /><br />


                                                <label id="updateuser_form_lable">Marital Status:
                                                    <select
                                                        className="updateuser_form_input"
                                                        id="maritalstatus"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.maritalstatus}
                                                    >
                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="Divorced">Divorced</option>
                                                    </select>
                                                </label>
                                                <br /><br />


                                                <label id="updateuser_form_lable">District:
                                                    <select
                                                        className="updateuser_form_input"
                                                        id="district"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.district}
                                                    >
                                                        <option value="Guntur">Guntur</option>
                                                        <option value="Hyderabad">Hyderabad</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </label>
                                                <br /><br />

                                                <label id="updateuser_form_lable">State:
                                                    <select
                                                        className="updateuser_form_input"
                                                        id="state"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.state}
                                                    >
                                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                        <option value="Telangana">Telangana</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </label>
                                                <br /><br />

                                                <label id="updateuser_form_lable">Country:
                                                    <select
                                                        className="updateuser_form_input"
                                                        id="country"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.country}
                                                    >
                                                        <option value="India">India</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </label>
                                                <br /><br />

                                                <label id="updateuser_form_lable">Status:
                                                    <select
                                                        className="updateuser_form_input"
                                                        id="status"
                                                        onChange={this.handleDropdownChange}
                                                        value={this.state.selectedUser.status}
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="09">Delete Account</option>
                                                    </select>
                                                </label>
                                                <br /><br />
                                            </div>
                                            <br />
                                            <div className='editreleasenotespopupform'>
                                                <button type="submit" style={{ float: 'right' }}>Send</button>

                                                <button className="close-btn" data-famid='' onClick={this.toogle_user_details_update_form_1} style={{ padding: "10px" }}>
                                                    Close form
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="templ_view_details">
                            <h4 className="templ_view_title">Full Name: {this.state.selectedUser.firstname + ' ' + this.state.selectedUser.lastname}</h4>
                            <h5> Display Name: {this.state.selectedUser.displayname}</h5>
                            <h5> Mobile: {this.state.selectedUser.mobile}</h5>
                            <h5> Gender: {this.state.selectedUser.gender}</h5>
                            <h5> DOB: {this.state.selectedUser.dob}</h5>
                            <h5> Marital Status: {this.state.selectedUser.maritalstatus}</h5>
                        </div>
                    </div>
                    <div>
                        <div className="addrelbtn">
                            <ul className="nav nav-pills" id="user_btns">
                                <li><button id="showfamilytree" type="submit" onClick={this.showfamilytree}>Family Members</button></li>
                                <li><button id="showfriendsbtn" type="submit" onClick={this.showfriends}>Friends</button></li>
                                <li><button id="showeventsbtn" type="submit" onClick={this.showeventslist}>Events</button></li>
                                <li><button id="showinvitations" type="submit" onClick={this.showinvitationslist}>Invitations</button></li>
                                <li><button id="showtemplates" type="submit" onClick={this.showtemplateslist}>Templates</button></li>
                                <li><button id="showusercircles" type="submit" onClick={this.showusercircleslist}>circles</button></li>
                            </ul>
                        </div>
                        <div className="addrelmainformcntr">

                            {/* ---------------------------Family Members List------------------------------- */}
                            <div className="showtree" id="closeform2">
                                <div className='table_container'>
                                    <div className='release_notes_tab'>
                                        {/* <table id="user_familymembers_table" key={this.state.usr_family_mem_list.rId}> */}
                                        <input id="user_search_box_1" type="text" placeholder="Search user family members..." />
                                        {this.state.usr_family_mem_list.length === 0 ? (
                                            <p>No Record's found</p>
                                        ) : (
                                            <table id="user_familymembers_table" key={this.state.usr_family_mem_list.rId}>
                                                <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Relation</th>
                                                        {this.state.role === "Admin" &&
                                                            <th>Action</th>
                                                        }
                                                    </tr>
                                                    {this.state.usr_family_mem_list && this.state.usr_family_mem_list.map((fmal) => (
                                                        <tr key={fmal.id}>
                                                            <td>{fmal.displayName}</td>
                                                            <td>{fmal.relationName}</td>
                                                            {this.state.role === "Admin" &&
                                                                <td>
                                                                    <button id='edit_release_notes' data-famid={fmal.id} data-famdisplayname={fmal.displayName} data-fammobile={fmal.mobile} data-famgender={fmal.gender} data-famrelationname={fmal.relationName} data-famdob={fmal.dob} type="submit" onClick={this.toogle_user_family_update_form}>Update</button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {this.state.show_user_family_update_form && (
                                            <div className="edit_app_notes">
                                                <div className="edit_app_notes-inner">
                                                    <h2>Update Family Details</h2>
                                                    <hr />
                                                    <form onSubmit={this.updateusrfamilydetails}>
                                                        <label >Member Id: {this.state.selected_family_member.id}</label>
                                                        <br />
                                                        <label>
                                                            Display Name:
                                                            <input id='edit_fam_display_name' defaultValue={this.state.selected_family_member.displayName} />
                                                        </label>

                                                        <label>
                                                            Mobile:
                                                            <input id='edit_fam_Mobile' type="tel" pattern="[0-9]{10}" maxLength={10} defaultValue={this.state.selected_family_member.mobile} />
                                                        </label>

                                                        <label>
                                                            Gender:
                                                            <input id='edit_fam_gender' defaultValue={this.state.selected_family_member.gender} readOnly />
                                                        </label>

                                                        <label>
                                                            Relation:
                                                            <input id='edit_fam_relation' defaultValue={this.state.selected_family_member.relationName} readOnly />
                                                        </label>
                                                        <br />
                                                        <label>
                                                            Date of Birth:
                                                            <input
                                                                type="date"
                                                                id="edit_fam_dob"
                                                                defaultValue={this.formatDate(this.state.selected_family_member.dob)}
                                                            />
                                                        </label>

                                                        <br />
                                                        <div className='editreleasenotespopupform'>
                                                            <button type="submit" style={{ float: 'right' }}>Send</button>
                                                            <button className="close-btn" data-famid='' onClick={this.toogle_user_family_update_form} style={{ padding: "10px" }}>
                                                                Close form
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                            {/* ---------------------------Friend's List------------------------------- */}
                            <div className="showfriends" id="closeform3">
                                <div className='table_container'>
                                    <div className='release_notes_tab'>
                                        {this.state.usr_frnd_list.length === 0 ? (
                                            <p>No Record's found</p>
                                        ) : (
                                            <table id="user_friends_table" key={this.state.usr_frnd_list.id}>
                                                <tbody>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Name</th>
                                                        {this.state.role === "Admin" &&
                                                            <th>Action</th>
                                                        }
                                                    </tr>
                                                    {this.state.usr_frnd_list && this.state.usr_frnd_list.map((ffal) => {
                                                        // var dob = ffal.dob.split("-")[2] + "-" + ffal.dob.split("-")[1] + "-" + ffal.dob.split("-")[0];
                                                        return <tr key={ffal.id}>
                                                            <td>{ffal.id}</td>
                                                            <td>{ffal.displayName}</td>
                                                            {this.state.role === "Admin" &&
                                                                <td>
                                                                    <button id='edit_release_notes' data-frndid={ffal.id} data-edisplayname={ffal.displayName} data-emobile1={ffal.mobile} data-selopt={ffal.gender} data-edob={ffal.dob} type="submit" onClick={this.toogle_user_frnd_update_form}>Update</button>
                                                                </td>
                                                            }
                                                        </tr>;
                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                        {this.state.show_user_frnd_update_form &&
                                            (
                                                <div className="edit_app_notes">
                                                    <div className="edit_app_notes-inner">
                                                        <h2>Update Friend Details</h2>
                                                        <form onSubmit={this.updateusrfrnddetails}>
                                                            <br />
                                                            <label>
                                                                Display Name:
                                                                <textarea id='edit_display_name' defaultValue={this.state.edisplayname} />
                                                            </label>
                                                            <br />
                                                            <label>
                                                                Mobile Number:
                                                                <input id='edit_number' type="number" maxLength={10} defaultValue={this.state.emobile1} />
                                                            </label>
                                                            <br />
                                                            <label>
                                                                Choose Gender:
                                                                <select id="frnd_gen_change" onChange={this.user_frnd_gen_change}>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Female</option>
                                                                </select>
                                                            </label>
                                                            <br />
                                                            <label>
                                                                Date of Birth:
                                                                <input type="date" id='edit_dob' defaultValue={this.state.edob || 'dd/mm/yyyy'} />
                                                            </label>
                                                            <br />
                                                            <div className='editreleasenotespopupform'>
                                                                <button type="submit" style={{ float: 'right' }}>Send</button>
                                                                <button className="close-btn" data-frndid='' data-frndisplayname='' data-frnmobile='' data-selopt='' onClick={this.toogle_user_frnd_update_form} style={{ padding: '10px' }}>
                                                                    Close form
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            {/* ---------------------------Customer Events List------------------------------- */}
                            <div className="showevents" id="closeform7">
                                <div id="events_sec">
                                    <h1>customer created Events List</h1>
                                    <table id="user_events_table">
                                        <tbody>
                                            <tr>
                                                <th>Event Name</th>
                                            </tr>
                                            {this.state.usr_event_list.length === 0 &&
                                                <tr>
                                                    <td>No Record's found</td>
                                                </tr>
                                            }
                                            {this.state.usr_event_list.map((uel) => (
                                                <tr>
                                                    <td>{uel.title}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <br />
                                    <table id="user_events_table">
                                        <tbody>
                                            <tr>
                                                <th> Customer Invited Event Name</th>
                                            </tr>
                                            {this.state.usr_inv_event_list.length === 0 &&
                                                <tr>
                                                    <td>No Record's found</td>
                                                </tr>
                                            }
                                            {this.state.usr_inv_event_list.map((uiel) => (
                                                <tr>
                                                    <td>{uiel.title}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <br />
                                    <button id='close_events_form' type="submit" onClick={this.closeevents}>Close</button>
                                </div>
                            </div>
                            {/* ---------------------------User circles List------------------------------- */}
                            <div className="showcircles" id="closeform4">
                                <div id="circles_sec">
                                    <h1>Circles List</h1>
                                    {this.state.usr_circle_list.length === 0 ? (
                                        <p>No Record's found</p>
                                    ) : (
                                        <table id="user_circles_table">
                                            <tbody>
                                                <tr>
                                                    <th>Circle Name</th>
                                                    <th>Count</th>
                                                </tr>
                                                {this.state.usr_circle_list.map((ucl) => (
                                                    <tr>
                                                        <td>{ucl.title}</td>
                                                        <td>{ucl.count}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <br />
                                    <button id='close_circles_form' type="submit" onClick={this.closecircles}>Close</button>
                                </div>
                            </div>
                            {/* ---------------------------User Invitation's List------------------------------- */}
                            <div className="showinvitations" id="closeform5">
                                <div id="invitations_sec">
                                    <h1>Invitations</h1>
                                    {this.state.usr_invitations_list.length === 0 ? (
                                        <p>No Record's found</p>
                                    ) : (
                                        <table id="user_invitations_table">
                                            <tbody>
                                                <tr>
                                                    <th>Invitation Id</th>
                                                    <th>Invitation Name</th>
                                                </tr>
                                                {this.state.usr_invitations_list.map((uil) => (
                                                    <tr>
                                                        <td>{uil.invId}</td>
                                                        <td>{uil.title}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <br />
                                    <button id='close_invitations_form' type="submit" onClick={this.closeinvitations}>Close</button>
                                </div>
                            </div>
                            {/* ---------------------------User Template's List------------------------------- */}
                            <div className="showtemplates" id="closeform6">
                                <div id="templates_sec">
                                    <h1>Templates</h1>
                                    {this.state.usr_templates_list.length === 0 ? (
                                        <p>No Record's found</p>
                                    ) : (
                                        <table id="user_templates_table">
                                            <tbody>
                                                <tr>
                                                    <th>Template Name</th>
                                                    <th>Cost</th>
                                                </tr>
                                                {this.state.usr_templates_list.map((utl) => (
                                                    <tr>
                                                        <td>{utl.title}</td>
                                                        <td>{utl.price}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                    <br />
                                    <button id='close_templates_form' type="submit" onClick={this.closetemplates}>Close</button>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    }
}
export default LayerDetails;