import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import { globalkey, globaladdress, s_key, s_secret } from '../variables'
import Header from '../../components/Header/header';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './cmr_event_management.css';

class PopupForm extends Component {
    signincheck() {
        if (!window.localStorage.getItem("isLoggedin")) {
            window.location.replace("/");
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            selectedEvent: null,
            selectedUsers: [],
            cmr_events: [],
            cmr_users: [],
            selectAllUsers: false,
        };
        this.handleEventSelect = this.handleEventSelect.bind(this);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.handleSendInvitations = this.handleSendInvitations.bind(this);
        this.handleSelectAllUsers = this.handleSelectAllUsers.bind(this);
    }

    componentDidMount() {
        fetch(globaladdress + "getcmrevents?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then(res => {
                this.setState({
                    cmr_events: res,
                });
            })
            .catch(error => {
                console.error('Fetch error', error);
            });
    }

    handleEventSelect(eventId) {
        fetch(globaladdress + "getcmrusers?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId + '&eventId=' + eventId)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then(res => {
                this.setState({
                    cmr_users: res,
                    selectedEvent: eventId,
                    selectedUsers: [],
                    selectAllUsers: false,
                });
            })
            .catch(error => {
                console.error('Fetch error', error);
            });
    }

    handleUserSelect(userId) {
        let updatedSelectedUsers = [...this.state.selectedUsers];
        const userIndex = updatedSelectedUsers.indexOf(userId);
        if (userIndex === -1) {
            updatedSelectedUsers.push(userId);
        } else {
            updatedSelectedUsers.splice(userIndex, 1);
        }
        this.setState({
            selectedUsers: updatedSelectedUsers,
            selectAllUsers: false,
        });
    }

    handleSelectAllUsers() {
        const { cmr_users, selectedUsers, selectAllUsers } = this.state;
        if (selectAllUsers) {
            this.setState({
                selectedUsers: [],
                selectAllUsers: false,
            });
        } else {
            const allUserIds = cmr_users.map(user => user.cdId);
            const updatedSelectedUsers = [...new Set([...selectedUsers, ...allUserIds])];
            this.setState({
                selectedUsers: updatedSelectedUsers,
                selectAllUsers: true,
            });
        }
    }

    handleSendInvitations() {
        // Extract selected event ID and user IDs
        const { selectedEvent, selectedUsers } = this.state;

        // Check if an event is selected and at least one user is selected
        if (selectedEvent && selectedUsers.length > 0) {
            // Display the selected event ID and user IDs in an alert
            alert(`Selected Event ID: ${selectedEvent}\nSelected User IDs: ${selectedUsers.join(', ')}`);
        } else {
            // If no event is selected or no users are selected, display an error message
            alert("Please select an event and at least one user to send invitations.");
        }
    }


    render() {
        const { selectedEvent, selectedUsers, selectAllUsers, cmr_users } = this.state;
        const isButtonDisabled = !selectedEvent || selectedUsers.length === 0;
        const showSelectAll = selectedEvent !== null;
        const allUsersSelectedManually = cmr_users.length > 0 && selectedUsers.length === cmr_users.length;
        return (
            <div className="container body">
                <div className='main_container'>
                    <div className='col-md-3 left_col'>
                        <Navbar username={localStorage.username} />
                    </div>
                    <Header />
                    <div className='right_col'>
                        <div className='cmr_event_management_flex_box'>
                            <div className='table_container'>
                                <div className='release_notes_tab'>
                                    <div className='cmr_event_list'>
                                        <h1>Events</h1>
                                        <ul>
                                            {this.state.cmr_events.map(cmr_e => (
                                                <li key={cmr_e.ceId}>
                                                    <input
                                                        type="radio"
                                                        name="event"
                                                        id={`event_${cmr_e.ceId}`}
                                                        value={cmr_e.ceId}
                                                        checked={this.state.selectedEvent === cmr_e.ceId}
                                                        onChange={() => this.handleEventSelect(cmr_e.ceId)}
                                                    />
                                                    <label htmlFor={`event_${cmr_e.ceId}`}>{cmr_e.title}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {showSelectAll && (
                                <div className='release_notes_tab'>
                                    <div className='cmr_members_list'>
                                        <h1>Event Members</h1>
                                        <ul>
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    id="selectAllUsers"
                                                    checked={selectAllUsers || allUsersSelectedManually}
                                                    onChange={this.handleSelectAllUsers}
                                                />
                                                <label htmlFor="selectAllUsers">Select All</label>
                                            </li>
                                            {cmr_users.map(cmr_u => (
                                                <li key={cmr_u.cdId}>
                                                    <input
                                                        type="checkbox"
                                                        id={`user_${cmr_u.cdId}`}
                                                        value={cmr_u.cdId}
                                                        checked={selectedUsers.includes(cmr_u.cdId)}
                                                        onChange={() => this.handleUserSelect(cmr_u.cdId)}
                                                    />
                                                    <label htmlFor={`user_${cmr_u.cdId}`}>{cmr_u.displayName}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="send_button_container">
                                <button onClick={this.handleSendInvitations} disabled={isButtonDisabled}>Send Invitations</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PopupForm;
