import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Navbar from "../../../components/Navbar";
import Header from '../../../components/Header/header';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { globalkey, globaladdress, s_key, s_secret } from '../../variables';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Styles = styled.div`
  padding: 1rem;
  table {
  border-spacing: 0;
  border: 1px solid black;
  width:80%;
  margin:10px;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th,td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    :last-child {
      border-right: 0;
    }
  }
}
`
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <div></div>
  )
}
function closeForm() {
  document.querySelector("#closeform").style.display = "none";
  document.querySelector('#showform').style.display = "flex";

}
function add(cell) {
  var tId = JSON.stringify(cell?.row?.original.userId)
  toast.info(tId)
  var relation_name = document.getElementById("res_name").value
  if (relation_name) {
    // alert("passed condition")
    // alert("relation name: "+relation_name);
    // alert("relation Id: "+relation_id);

    try {
      // axios.post(globaladdress+"address?relationName="+relation_name+"&relId="+relation_id+"&key="+globalkey+ '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId).then(()=>{window.location.reload()})
      toast.success("function called in try bloack");
    } catch {
      toast.error("api call skipped!!")
    }


  } else {
    toast.warning("Please fill the required feilds!")
  }

}

const handleShow = (cell) => {
  if (window.localStorage.getItem('role') !== "Admin") {
    toast.warning("You are not authorized to perform this action!")
  } else {
    var stat = JSON.stringify(cell?.row?.original.userId)
    // getuserdetails = api call
    toast.info(stat);

    document.querySelector(".addresform").style.display = "flex";
    // getuserdetails
    document.querySelector('#showform').style.display = "none";
  }

}
function Table({ columns, data }) {
  const filterTypes = (
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    showForm,
    // setPageSize,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )
  const firstPageRows1 = page.slice(0, 9)
  return (
    <>
      <h1>User issues</h1>
      <div>
        <div className="addresponseform">
          <div className="addresform" id="closeform">
            <h1>Add Response</h1>
            <form className="add_res_form" id="addresponse_form">
              <h1>yo!</h1>
              <br />
            </form>
            <div id="form_btn_section">
              <span><button id='addrel' type='submit' onClick={add}>Add Response</button>
                <button id='close_rel_form' type="submit" onClick={closeForm}>Close</button></span>
            </div>
          </div>
        </div>
        <table className='table data_table' {...getTableProps()}>
          <thead>
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: 'left',
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows1.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>

        </table>
      </div>
      <br />
      <div id='filter_det'>
        Showing the{" "}
        {pageIndex * 10 + 1}-
        {(pageIndex + 1) * 10 > rows.length ? rows.length : (pageIndex + 1) * 10} results of {rows.length} rows      </div>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"First"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Last"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const rows = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(rows);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}

      </div>
    </>
  )
}
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}
filterGreaterThan.autoRemove = val => typeof val !== 'number'
function App() {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const openEditModal = (row) => {
    setSelectedRow(row);
    setEditModalOpen(true);
  };
  const closeEditModal = () => {
    setSelectedRow(null);
    setEditModalOpen(false);
  };
  const populateEditForm = () => {
    if (!selectedRow) return null;
    const reportId = selectedRow.original.id;
    const tab_userId = selectedRow.original.userId;
    const ComplaintId = selectedRow.original.reportedId;
    const reason = selectedRow.original.reason;
    const status = selectedRow.original.status;

    return (
      <>
        <div className='edit_relation_lkp_form'>
          <label>Report Id :&ensp;&emsp;&emsp;</label>
          <input id="edit_reportId" type="none" defaultValue={reportId} readOnly />
          <br></br>
          <label>User Id :&ensp;&emsp;&emsp;&emsp;</label>
          <input id="edit_tab_userId" type="none" defaultValue={tab_userId} readOnly />
          <br></br> <label>Complaint Id :&ensp;&nbsp;</label>
          <input id="edit_ComplaintId" type="none" defaultValue={ComplaintId} readOnly />
          <br></br> <label>Reason :&ensp;&emsp;&emsp;&emsp;</label>
          <input id="edit_reason" type="none" defaultValue={reason} readOnly />
          <br></br>
          <hr></hr>
          <label>Status: &ensp;&emsp;&emsp;&emsp;</label>
          <input id="edit_status" type="text" defaultValue={status} />
          <br /><br />

        </div>
      </>
    );
  };
  const updatereport = () => {
    // const editedRelationName = document.getElementById('edit_rel_name').value;
    // const editedRelId = document.getElementById('edit_rel_id').value;
    // alert("editedRelationName: " + editedRelationName); // Concatenate the values
    // alert("editedRelId: " + editedRelId);
    toast.warning("api call is not updates!")
    // axios.post(`${globaladdress}?relName=${editedRelationName}&relId=${editedRelId}&key=${globalkey}`)
    // .then((response) => {
    //   console.log('Data updated successfully');
    //   closeEditModal();
    // })
    // .catch((error) => {
    //   console.log("Update not went as smooth as expected!")
    // });
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    let sessionTimeOutAlertShown = false;
    fetch(globaladdress + "getuserreports?key=" + globalkey + '&token=' + localStorage.session_token + '&unq_key=' + s_key + '&secret=' + s_secret + '&tsId=' + localStorage.tId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.text();
      })
      .then((resText) => {
        if (resText === "Session timeOut!" && !sessionTimeOutAlertShown) {
          sessionTimeOutAlertShown = true;
          alert("Session timeOut!");
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          const res = JSON.parse(resText);
          setData(res)

        }
      }).catch((error) => {
        console.log("Fetch error: ", error);
      })
  }, [])

  const columns = React.useMemo(() => [{
    Header: 'Name',
    columns: [

      {
        Header: 'Report Id',
        accessor: 'id',
      },
      {
        Header: 'User Id',
        accessor: 'userId',
      },
      {
        Header: 'Complaint Id',
        accessor: 'reportedId',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      ...(window.localStorage.getItem('role') !== "User" ? [
        {
          Header: 'Edit',
          accessor: 'editrel',
          Footer: 'Edit',
          Cell: ({ row }) => (
            <button id='edit_issue_btn' onClick={() => openEditModal(row)}>Edit</button>
          ),
        }
      ] : [])


    ],
  },
  ]
  )
  return (
    <div className="container body">
      <div className='main_container'>
        <div className='col-md-3 left_col'>
          <Navbar username={localStorage.username} />
        </div>
        {/* <div className='page-content'> */}
        <Header />
        <div className='right_col'>
          <div className='container2'>
            <div className='content'>
              <Styles>

                <Table columns={columns} data={data} />
                {/* <UserData data={data}/> */}

              </Styles></div>
            <Modal show={isEditModalOpen} onHide={closeEditModal}>
              <div className='edit_rel_lkp_model_container'>
                <Modal.Header>
                  <Modal.Title>Edit User Report's</Modal.Title>
                  <h1>Update api call to be updated</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={closeEditModal}>
                    <i className="fa fa-times"></i>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    {populateEditForm()}
                  </form>
                </Modal.Body>
                <div className='edit_rel_lkp_model_footer' >
                  <Modal.Footer>
                    <div className='edit_rel_lkp_model_btn'>
                      <Button id='rel_lkp_update_btn' variant="primary" onClick={updatereport}>
                        Update
                      </Button>
                    </div>
                  </Modal.Footer>
                </div>
              </div>
            </Modal>

          </div>
        </div>
      </div>
    </div>
  )
}
export default App